import { all } from 'redux-saga/effects'
import { loginSaga } from 'pages/Login/slice/saga'
import { authSaga } from 'routes/slice/saga'
import { customerSaga } from 'pages/Customer/slice/saga'
import { customerDetailsSaga } from 'components/CustomerDetails/slice/saga'
import { managerSaga } from 'pages/Managers/slice/saga'
import { paymentSaga } from 'pages/Payments/slice/saga'
import { orderSaga } from 'pages/Orders/slice/saga'
import { productSaga } from 'pages/Products/slice/saga'
import { rateSaga } from 'pages/Rates/slice/saga'
import { dashboardSaga } from 'pages/Dashboard/slice/saga'
import { ambassadorSaga } from 'pages/StudentAmbassador/slice/saga'

export function* helloSaga() {
    yield console.log('Hello Sagas!')
}

export default function* rootSaga() {
    yield all([
        helloSaga(),
        loginSaga(),
        authSaga(),
        customerSaga(),
        customerDetailsSaga(),
        managerSaga(),
        paymentSaga(),
        orderSaga(),
        productSaga(),
        rateSaga(),
        dashboardSaga(),
        ambassadorSaga(),
    ])
}
