import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import SignIn from 'pages/Login'
import Dashboard from 'pages/Dashboard/Dashboard'
import Customer from 'pages/Customer'
import Managers from 'pages/Managers'
import Payments from 'pages/Payments'
import Orders from 'pages/Orders'
import Products from 'pages/Products'
import Rates from 'pages/Rates'
import StudentAmbassador from 'pages/StudentAmbassador'
import ProtectedRoute from 'components/ProtectedRoute'
import {
    hasPermission,
    ORDERS_PAGE,
    STUDENT_AMBASSADOR_PAGE,
    PRODUCTS_PAGE,
    RATES_PAGE,
    PAYMENTS_PAGE,
    MANAGERS_PAGE,
    CUSTOMERS_PAGE,
    DASHBOARD_PAGE,
} from 'utils/permission'

const Router = () => {
    const { initUserSession, currentUser } = useAuth()

    useEffect(() => {
        initUserSession()
    }, [])

    const { email } = currentUser || {}

    return (
        <Routes>
            {hasPermission(email, STUDENT_AMBASSADOR_PAGE) && (
                <Route
                    path="/student-ambassador"
                    element={
                        <ProtectedRoute pageTitle="Student Ambassadorship">
                            <StudentAmbassador />
                        </ProtectedRoute>
                    }
                />
            )}

            {hasPermission(email, ORDERS_PAGE) && (
                <Route
                    path="/orders"
                    element={
                        <ProtectedRoute pageTitle="Orders">
                            <Orders />
                        </ProtectedRoute>
                    }
                />
            )}

            {hasPermission(email, PRODUCTS_PAGE) && (
                <Route
                    path="/products"
                    element={
                        <ProtectedRoute pageTitle="Products">
                            <Products />
                        </ProtectedRoute>
                    }
                />
            )}

            {hasPermission(email, RATES_PAGE) && (
                <Route
                    path="/rates"
                    element={
                        <ProtectedRoute pageTitle="Rates">
                            <Rates />
                        </ProtectedRoute>
                    }
                />
            )}

            {hasPermission(email, PAYMENTS_PAGE) && (
                <Route
                    path="/payments"
                    element={
                        <ProtectedRoute pageTitle="Payments">
                            <Payments />
                        </ProtectedRoute>
                    }
                />
            )}

            {hasPermission(email, MANAGERS_PAGE) && (
                <Route
                    path="/managers"
                    element={
                        <ProtectedRoute pageTitle="Managers">
                            <Managers />
                        </ProtectedRoute>
                    }
                />
            )}

            {hasPermission(email, CUSTOMERS_PAGE) && (
                <Route
                    path="/customers"
                    element={
                        <ProtectedRoute pageTitle="Customers">
                            <Customer />
                        </ProtectedRoute>
                    }
                />
            )}

            {hasPermission(email, DASHBOARD_PAGE) && (
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute pageTitle="Dashboard">
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
            )}

            <Route
                path="/"
                element={
                    <ProtectedRoute notProtected>
                        <SignIn />
                    </ProtectedRoute>
                }
            />
        </Routes>
    )
}

export default Router
