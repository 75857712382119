import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/customer'

import customerSlice from '.'
const { actions } = customerSlice

export function* getCustomers(action) {
    try {
        const response = yield call(apis.getCustomers, action.payload)
        const { data } = response
        yield put(actions.getCustomersSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getCustomersError(data))
    }
}

export function* exportCustomers(action) {
    try {
        const response = yield call(apis.exportCustomers, action.payload)
        const { data } = response
        yield put(actions.exportCustomersSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.exportCustomersError(data))
    }
}

export function* customerSaga() {
    yield takeLatest(actions.getCustomers.type, getCustomers)
    yield takeLatest(actions.exportCustomers.type, exportCustomers)
}
