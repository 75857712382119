export const adminRolesOptions = [
    {
        label: 'Super Admin',
        value: 'superadmin',
    },
    {
        label: 'Admin',
        value: 'admin',
    },
]

export const userStatusOptions = [
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Blocked',
        value: 'Blocked',
    },
]
