/* eslint-disable react/prop-types */
import { Button } from 'components/Button'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ConfirmModalWrapper } from './ConfirmBox.style'

export function confirm({ confirmText, isLoading, onConfirm, multiple }) {
    return confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <ConfirmModalWrapper className="test">
                    <div className="text">{confirmText}</div>
                    <div className="btn-container">
                        <Button
                            className="confirm-btn-primary"
                            disabled={isLoading}
                            isLoading={isLoading}
                            key={isLoading}
                            variant="primary"
                            type="button"
                            onClick={async () => {
                                if (!multiple) {
                                    onClose()
                                }
                                return onConfirm()
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            className="confirm-btn-secondary"
                            disabled={isLoading}
                            variant="secondary"
                            type="button"
                            onClick={onClose}
                        >
                            No
                        </Button>
                    </div>
                </ConfirmModalWrapper>
            )
        },
        closeOnClickOutside: false,
    })
}
