import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/order'

import orderSlice from '.'
const { actions } = orderSlice

export function* getOrders(action) {
    try {
        const response = yield call(apis.getOrders, action.payload)
        const { data } = response
        yield put(actions.getOrdersSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getOrdersError(data))
    }
}

export function* getSingleOrder(action) {
    try {
        const response = yield call(apis.getSingleOrder, action.payload)
        const { data } = response
        yield put(actions.getSingleOrderSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getSingleOrderError(data))
    }
}

export function* updateOrder(action) {
    try {
        const { id, ...payload } = action.payload
        const response = yield call(apis.updateOrder, id, payload.dataForm)
        const { data } = response
        yield put(actions.updateOrderSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.updateOrderError(data))
    }
}

export function* creditOrder(action) {
    try {
        const { id, ...payload } = action.payload
        const response = yield call(apis.creditOrder, id, payload)
        const { data } = response
        yield put(actions.creditOrderSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.creditOrderError(data))
    }
}

export function* orderSaga() {
    yield takeLatest(actions.getOrders.type, getOrders)
    yield takeLatest(actions.getSingleOrder.type, getSingleOrder)
    yield takeLatest(actions.updateOrder.type, updateOrder)
    yield takeLatest(actions.creditOrder.type, creditOrder)
}
