import { combineReducers } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import authSlice from 'routes/slice'
import loginSlice from 'pages/Login/slice'
import customerSlice from 'pages/Customer/slice'
import customerDetailsSlice from 'components/CustomerDetails/slice'
import managerSlice from 'pages/Managers/slice'
import paymentSlice from 'pages/Payments/slice'
import orderSlice from 'pages/Orders/slice'
import productSlice from 'pages/Products/slice'
import rateSlice from 'pages/Rates/slice'
import dashboardSlice from 'pages/Dashboard/slice'
import ambassadorSlice from 'pages/StudentAmbassador/slice'

const reducerSlice = createSlice({
    name: 'global',
    initialState: { name: 'Olamide' },
    reducers: {
        someAction: function () {
            console.log('first')
        },
    },
})

export default function createReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        ...injectedReducers,
        global: reducerSlice.reducer,
        auth: authSlice.reducer,
        login: loginSlice.reducer,
        customer: customerSlice.reducer,
        customerDetails: customerDetailsSlice.reducer,
        manager: managerSlice.reducer,
        payment: paymentSlice.reducer,
        order: orderSlice.reducer,
        product: productSlice.reducer,
        rate: rateSlice.reducer,
        dashboard: dashboardSlice.reducer,
        ambassador: ambassadorSlice.reducer,
    })
    return rootReducer
}
