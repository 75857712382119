import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

// import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import createReducer from './reducers'
import sagas from './sagas'

export default function configureStore(preloadedState?: object) {
    const sagaMiddleware = createSagaMiddleware()
    const middlewares = [loggerMiddleware, sagaMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]
    // monitorReducersEnhancer
    const composedEnhancers = composeWithDevTools(...enhancers)

    const store = createStore(
        createReducer(),
        preloadedState,
        composedEnhancers,
    )

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () =>
            store.replaceReducer(createReducer()),
        )
    }

    // then run the saga
    sagaMiddleware.run(sagas)

    return store
}
