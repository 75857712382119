import axiosCustom from 'utils/axiosCustom'
import { paramsObjectToQueryString } from 'utils/parser'

const apis = {
    getAmbassadors: params =>
        axiosCustom(`/student-ambassador${paramsObjectToQueryString(params)}`),

    exportAmbassadors: params =>
        axiosCustom(
            `/student-ambassador/export${paramsObjectToQueryString(params)}`,
        ),
}

export default apis
