export const OUTSTANDING_BALANCE = 'outstanding_balance'
export const DASHBOARD_STATS = 'dashboard_stats'
export const CUSTOMERS_PAGE = '/customers'
export const ORDERS_PAGE = '/orders'
export const PAYMENTS_PAGE = '/payments'
export const DASHBOARD_PAGE = '/dashboard'
export const STUDENT_AMBASSADOR_PAGE = '/student-ambassador'
export const PRODUCTS_PAGE = '/products'
export const RATES_PAGE = '/rates'
export const MANAGERS_PAGE = '/managers'

export const whitelists = [
    {
        email: 'internalaudit01@myecurrencyng.com',
        pages: [CUSTOMERS_PAGE, ORDERS_PAGE, PAYMENTS_PAGE, DASHBOARD_PAGE],
        actions: [OUTSTANDING_BALANCE],
    },
]

const included = email => whitelists.find(item => item.email === email)

export const hasPermission = (email, permission) => {
    const user = included(email)
    if (user) {
        return (
            user.pages.includes(permission) || user.actions.includes(permission)
        )
    }

    return true
}
