import styled from 'styled-components'
import PropTypes from 'prop-types'
import DialogTitle from '@mui/material/DialogTitle'
import Modal from 'components/Modal'
import { Button } from 'components/Button'
import { Status } from 'styles/utils'
import { Spinner } from 'components/Spinner'
import ErrorIcon from 'assets/icons/error.svg'

const TransactionDetailsWrapper = styled.div`
    max-width: 100%;
    width: 800px;
    padding: 0 30px 30px;

    .transactiond-data {
        h6 {
            font-size: 16px;
            margin-right: 10px;
        }
    }
`

export default function TransactionDetails({
    open,
    onClose,
    payment,
    title,
    handleRefund,
    loading,
    error,
    onError,
}) {
    console.log(error)
    const {
        transfer_status,
        message,
        messsage,
        transaction,
        payment: paymentEc,
    } = payment || {}

    const {
        merchant_reference,
        payment_data,
        transaction_reference,
        bill_data,
    } = transaction || {}

    const invalidMerchantRef = transfer_status === 'invalid_merchant_reference'

    const transferFailed = transfer_status === 'failed' || invalidMerchantRef

    const details = [
        {
            title: 'Payment ID',
            value: paymentEc?._id,
            type: 'text',
        },
        {
            title: 'Merchant Reference',
            value: merchant_reference || 'N/A',
            type: 'text',
        },
        {
            title: 'WATU Transaction Ref',
            value: transaction_reference || 'N/A',
            type: 'text',
        },
        {
            title: 'WATU Transaction Status',
            value: invalidMerchantRef ? 'failed' : transfer_status,
            type: 'tag',
        },
        transferFailed
            ? {
                  title: 'Refund Completed',
                  value: paymentEc?.refund ? 'yes' : 'no',
                  type: 'tag',
              }
            : {},

        {
            title: 'Message',
            value: message || messsage,
            type: 'text',
        },
    ]

    const paymentData = [
        {
            title: 'Session ID',
            value: bill_data ? bill_data['SESSION ID'] : '',
            type: 'text',
        },
        {
            title: 'Amount Paid',
            value: `${payment_data?.currency}${payment_data?.amount}`,
            type: 'text',
        },
        {
            title: 'Charges',
            value: `${payment_data?.currency}${payment_data?.fees}`,
            type: 'text',
        },
        {
            title: 'Total Debit',
            value: `${payment_data?.currency}${payment_data?.total_amount}`,
            type: 'text',
        },
    ]

    const detailsList = details.filter(value => Object.keys(value).length !== 0)

    return (
        <Modal open={open} onClose={onClose} maxWidth="1000px">
            {title && <DialogTitle>{title} </DialogTitle>}
            {loading && (
                <div>
                    <Spinner />
                    <p className="text-center mt-3"> Loading...</p>
                </div>
            )}
            <TransactionDetailsWrapper>
                {paymentEc ? (
                    <>
                        <ul className="transactiond-data">
                            <h5 className="mb-2">Payment Information</h5>
                            {detailsList?.map(item => (
                                <li className="mb-1" key={item.value}>
                                    <div className="d-flex align-items-center">
                                        <h6>{item.title}:</h6>
                                        <div>
                                            {item.type === 'tag' ? (
                                                <Status status={item.value}>
                                                    {item.value}
                                                </Status>
                                            ) : (
                                                <p>{item.value}</p>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        {(payment_data ||
                            (bill_data && bill_data['SESSION ID'])) && (
                            <ul className="transactiond-data mt-5">
                                <h5 className="mb-2">
                                    WATU Transaction Details
                                </h5>
                                {paymentData?.map(item => (
                                    <li className="mb-1" key={item.value}>
                                        <div className="d-flex align-items-center">
                                            <h6>{item.title}:</h6>
                                            <div>
                                                {item.type === 'tag' ? (
                                                    <Status status={item.value}>
                                                        {item.value}
                                                    </Status>
                                                ) : (
                                                    <p>{item.value}</p>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {transferFailed &&
                            !paymentEc?.refund &&
                            paymentEc?._id && (
                                <div className="mt-3">
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            handleRefund(paymentEc._id)
                                        }
                                    >
                                        Process Refund
                                    </Button>
                                </div>
                            )}
                    </>
                ) : (
                    <h5 className="text-center mt-3">{message || messsage}</h5>
                )}

                {error && (
                    <div className="mt-3">
                        <div className="d-flex justify-content-center">
                            <img
                                src={ErrorIcon}
                                alt="error"
                                className=" mb-2"
                            />
                        </div>
                        <h4 className="text-center mb-4">
                            {error?.message || 'Payment not found!'}
                        </h4>
                        <div className="d-flex justify-content-center">
                            <Button onClick={onError}>Done</Button>
                        </div>
                    </div>
                )}
            </TransactionDetailsWrapper>
        </Modal>
    )
}

TransactionDetails.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    payment: PropTypes.object,
    handleRefund: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
    onError: PropTypes.func,
}
