import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    customer: {},
    transactions: {},
    penalty: null,
    penaltyLoading: false,
}

const customerDetailsSlice = createSlice({
    name: 'customerDetails',
    initialState,
    reducers: {
        getSingleCustomer: state => {
            state.loading = true
            state.error = false
        },
        getSingleCustomerSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.customer = action.payload
        },
        getSingleCustomerError: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        getCustomerTransactions: state => {
            state.loading = true
            state.error = false
        },
        getCustomerTransactionsSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.transactions = action.payload
        },
        getCustomerTransactionsError: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        penalizeCustomer: state => {
            state.penaltyLoading = true
            state.error = false
        },
        penalizeCustomerSuccess: (state, action) => {
            state.penaltyLoading = false
            state.error = false
            state.penalty = action.payload
        },
        penalizeCustomerError: (state, action) => {
            state.penaltyLoading = false
            state.error = action.payload
        },
        reset(state) {
            state.penalty = null
        },
    },
})

export default customerDetailsSlice
