import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    managers: null,
    addManager: null,
}

const managerSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        getManagers: state => {
            state.loading = true
            state.error = false
        },
        getManagersSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.managers = action.payload
        },
        getManagersError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.managers = null
        },
        addManager: state => {
            state.loading = true
            state.error = false
        },
        addManagerSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.addManager = action.payload
        },
        addManagerError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.addManager = null
        },
        reset(state) {
            state.loading = false
            state.error = false
            state.managers = null
            state.addManager = null
        },
    },
})

export default managerSlice
