import styled from 'styled-components'

export const Status = styled.span`
    background-color: ${({ status }) => {
        switch (status) {
            case 'accepted':
            case 'success':
            case 'approved':
            case 'on':
            case 'completed':
            case 'active':
            case 'delivered':
            case 'credit':
            case 'yes':
            case true:
                return '#EAFBF1'

            case 'rejected':
            case 'off':
            case 'unapproved':
            case 'defaulted':
            case 'blocked':
            case 'declined':
            case 'debit':
            case 'failed':
            case 'no':
            case false:
                return '#FCE9E9'

            case 'pending':
            case 'restricted':
            case 'order':
                return '#e3f0fd'

            case null:
            case '':
                return '#FDF2E7'

            default:
                return '#00a3ff'
        }
    }};
    color: ${({ status }) => {
        switch (status) {
            case 'accepted':
            case 'approved':
            case 'on':
            case 'completed':
            case 'active':
            case 'delivered':
            case 'success':
            case 'credit':
            case 'yes':
            case true:
                return '#41D87D'

            case 'rejected':
            case 'unapproved':
            case 'off':
            case 'defaulted':
            case 'blocked':
            case 'declined':
            case 'debit':
            case 'failed':
            case 'no':
            case false:
                return '#E43535'

            case 'pending':
            case 'restricted':
            case 'order':
                return '#1354d2'

            case null:
            case '':
                return '#F08829'

            default:
                return '#fff'
        }
    }};

    padding: 0.5rem;
    border-radius: 4px;
    text-transform: capitalize;

    max-height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`
