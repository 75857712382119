/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Pagination from '@mui/material/Pagination'
import { v4 as uuid } from 'uuid'
import { TableWrapper } from './Table.style'

type TableCellProps = {
    headerName: string
    field: string
    renderCell?: (item: object) => JSX.Element
}

type TableProps = {
    data: object[]
    columns: TableCellProps[]
    isLoading?: boolean
    transform?: string
    count: number
    currentPage: number
    onPageChange: () => null
}

const BasicTable = ({
    columns,
    data,
    isLoading,
    count,
    currentPage,
    onPageChange,
    ...rest
}: TableProps): JSX.Element => {
    return (
        <TableWrapper>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            {...rest}
                        >
                            <TableHead>
                                <TableRow>
                                    {columns?.map(item => (
                                        <TableCell
                                            key={item.field}
                                            align="left"
                                        >
                                            {item.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((item: any) => {
                                    return (
                                        <TableRow
                                            key={item.id}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                        >
                                            {columns?.map((col, index) => {
                                                const value = item[col.field]
                                                return (
                                                    <TableCell
                                                        align="left"
                                                        key={uuid()}
                                                        {...(index === 0 && {
                                                            component: 'th',
                                                            scope: 'row',
                                                        })}
                                                    >
                                                        {col.renderCell
                                                            ? col.renderCell(
                                                                  item,
                                                              )
                                                            : value || ' - '}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="mt-3 d-flex justify-content-end">
                        <Pagination
                            count={count}
                            variant="outlined"
                            shape="rounded"
                            size="medium"
                            page={currentPage}
                            onChange={onPageChange}
                        />
                    </div>
                </>
            )}
        </TableWrapper>
    )
}

export default BasicTable
