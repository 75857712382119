import { useState, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { confirm } from 'components/ConfirmBox'
import { Button } from 'components/Button'
import Table from 'components/Table'
import { Status } from 'styles/utils'
import { numberWithCommas } from 'utils/numbers'
import FilterModal from 'components/FilterModal'
import { MenuComponent } from 'components/Menu'
import { getBank } from 'utils/bank'
import { filters } from '../../utils'
import paymentSlice from '../../slice'
import TransactionDetails from '../TransactionDetails'
import { Input } from '@mui/material'
import { LoadingModal } from 'components/LoadingModal'

export default function PendingTransfers({
    filterModalVisibility,
    setFilterModalVisibility,
}) {
    const bankUrl =
        'https://api.ravepay.co/v2/banks/NG?public_key=FLWPUBK-0ce0fb97ef10b0bf9e18fb8fb0d6506b-X'
    const LIMIT = 15
    const { actions } = paymentSlice
    const dispatch = useDispatch()
    const [banks, setBanks] = useState([])
    const [skip, setSkip] = useState(0)
    const [isQuery, setIsQuery] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [filterData, setFilterData] = useState({})
    const [searchValue, setSearchValue] = useState('')
    const [isQuerying, setIsQuerying] = useState(false)
    const [refundActive, setRefundActive] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const {
        pendingTransfers,
        loading,
        updatedPayment,
        queryTransfer,
        refundSuccess,
        updating,
        querying,
        queryError,
        refundError,
    } = useSelector(state => ({
        pendingTransfers: state.payment.pendingTransfers,
        loading: state.payment.loading,
        updatedPayment: state.payment.updatedPayment,
        queryTransfer: state.payment.queryTransfer,
        refundSuccess: state.payment.refundSuccess,
        updating: state.payment.updating,
        querying: state.payment.querying,
        queryError: state.payment.queryError,
        refundError: state.payment.refundError,
    }))

    const fetchBankList = async () => {
        try {
            const banks = await axios(bankUrl)
            setBanks(banks.data?.data?.Banks)
        } catch (e) {
            toast.error('Could not get list of banks')
        }
    }

    const getPendingTransfers = () => {
        const { status, ...rest } = filterData
        let payload = {
            ...rest,
            ...(status && { status: status.value }),
        }
        dispatch(
            actions.getPendingTransfers({ limit: LIMIT, skip, ...payload }),
        )
    }

    const handleQueryTransfer = id => {
        setIsQuerying(true)
        dispatch(
            actions.queryTransfer({
                payment_id: id,
            }),
        )
    }

    const handleRefund = id => {
        setIsQuery(false)

        confirm({
            confirmText: `Are you sure you want process a refund for this transaction?`,
            isLoading: false,
            onConfirm: async () => {
                setRefundActive(true)
                dispatch(
                    actions.refundFailedTransfer({
                        payment_id: id,
                    }),
                )
            },
        })
    }

    useEffect(() => {
        fetchBankList()

        return () => {
            dispatch(actions.reset())
        }
    }, [])

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    useEffect(() => {
        if (updatedPayment) {
            toast.success(updatedPayment?.message)
            getPendingTransfers()
        }
    }, [updatedPayment])

    useEffect(() => {
        getPendingTransfers()
    }, [skip, filterData])

    useEffect(() => {
        if (queryTransfer) {
            setIsQuery(queryTransfer)
            setIsQuerying(false)
        }
    }, [queryTransfer])

    const options = [
        {
            name: 'Query',
        },
        {
            name: 'Refund',
        },
    ]

    const columns = [
        {
            field: 'user',
            headerName: 'Email',
            renderCell: ({ user }) => {
                return <p className="text-lowercase">{user.email}</p>
            },
        },
        {
            field: 'account_no',
            headerName: 'Account No',
        },
        {
            field: 'bank',
            headerName: 'Bank',
            renderCell: row => {
                return <p>{getBank(row.bank, banks)}</p>
            },
        },
        {
            field: 'account_name',
            headerName: 'A/C Name',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.amount)}</p>
            },
        },
        {
            field: 'balance',
            headerName: 'Balance',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.balance)}</p>
            },
        },
        {
            field: 'transfer_status',
            headerName: 'Transfer Status',
            renderCell: ({ transfer_status }) => {
                return (
                    <Status status={transfer_status}>{transfer_status}</Status>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: ({ status }) => {
                return <Status status={status}>{status}</Status>
            },
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return (
                    <p>
                        {dayjs(row.createdAt).format('MMMM D, YYYY')} {' | '}
                        {dayjs(row.createdAt).format('hh:mmA')}
                    </p>
                )
            },
        },
        {
            field: '',
            width: 0,
            renderCell: row =>
                row.transfer_status === 'pending' ? (
                    <div>
                        <MenuComponent
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            open={open}
                            options={options}
                            icon={() => <MoreVertIcon />}
                            optionsFns={[
                                () => {
                                    setSearchValue('')
                                    handleQueryTransfer(row._id)
                                },
                                () => {
                                    setSearchValue('')
                                    handleRefund(row._id)
                                },
                                // place the callbacks in position corresponding to the options index
                            ]}
                        />
                    </div>
                ) : null,
        },
    ]

    const handleFilter = data => {
        setFilterData(data)
        setCurrentPage(1)
    }

    const onClose = () => {
        setIsQuery(false)
        setIsQuerying(false)
        dispatch(actions.resetSelect({ queryTransfer: null, queryError: null }))
    }

    const onError = () => {
        setRefundActive(false)
        dispatch(actions.resetSelect({ refundError: null }))
        onClose()
        getPendingTransfers()
    }

    const onRefundSuccess = () => {
        setRefundActive(false)
        dispatch(actions.resetSelect({ refundSuccess: null }))
        onClose()
        getPendingTransfers()
    }

    const count =
        pendingTransfers?.count > LIMIT
            ? Math.ceil(pendingTransfers?.count / LIMIT)
            : 1

    return (
        <div>
            <div className="me-3 d-flex mb-5" style={{ width: '100%' }}>
                <Input
                    placeholder={`Enter payment id`}
                    value={searchValue}
                    name="search"
                    type="text"
                    onChange={e => setSearchValue(e.target.value)}
                    style={{ width: '100%' }}
                />
                <div className="ms-4">
                    <Button
                        variant="contained"
                        disabled={!searchValue}
                        onClick={() => handleQueryTransfer(searchValue)}
                    >
                        Query Transfer
                    </Button>
                </div>
            </div>
            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={pendingTransfers?.data}
            />

            <FilterModal
                open={filterModalVisibility}
                onClose={() => setFilterModalVisibility(false)}
                title="Filter Payments"
                handleSubmit={handleFilter}
                filterData={filterData}
                filters={filters}
            />

            <TransactionDetails
                open={!!isQuery || isQuerying}
                onClose={onClose}
                onError={onClose}
                title="Query Transfer"
                payment={queryTransfer}
                handleRefund={id => handleRefund(searchValue || id)}
                loading={querying}
                {...((queryError || refundError) && {
                    error: {
                        message: queryError?.message || refundError?.message,
                    },
                })}
            />

            {refundActive && (
                <LoadingModal
                    description="Processing Refund..."
                    loading={updating}
                    onClose={() => null}
                    {...(refundSuccess && {
                        success: {
                            message: refundSuccess?.message,
                        },
                    })}
                    {...(refundError && {
                        error: {
                            message: refundError?.message,
                        },
                    })}
                    onError={onError}
                    onSuccess={onRefundSuccess}
                />
            )}
        </div>
    )
}

PendingTransfers.propTypes = {
    filterModalVisibility: PropTypes.bool,
    setFilterModalVisibility: PropTypes.func,
}
