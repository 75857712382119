import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { StatsCards } from 'components/StatsCards'
import { numberWithCommas } from 'utils/numbers'
import { useAuth } from 'hooks/auth'
import Typography from '@mui/material/Typography'
import {
    hasPermission,
    OUTSTANDING_BALANCE,
    DASHBOARD_STATS,
} from 'utils/permission'
import dashboardSlice from './slice'

const mdTheme = createTheme()

function DashboardContent() {
    const { actions } = dashboardSlice
    const dispatch = useDispatch()
    const { currentUser } = useAuth()

    const { stats, balance, loading, walletBalance, walletBalanceLoading } =
        useSelector(state => ({
            stats: state.dashboard.stats,
            balance: state.dashboard.balance,
            loading: state.dashboard.loading,
            walletBalance: state.dashboard.walletBalance,
            walletBalanceLoading: state.dashboard.walletBalanceLoading,
        }))
    const { email } = currentUser || {}

    useEffect(() => {
        dispatch(actions.getDashboardStats())
        dispatch(actions.getOutstandingBalance())
        dispatch(actions.getWalletBalance())
    }, [])

    const { total_orders, total_users, ...restStats } = stats

    return (
        <ThemeProvider theme={mdTheme}>
            <Typography variant="h3" gutterBottom>
                Dashboards
            </Typography>

            <div className="row">
                {hasPermission(email, DASHBOARD_STATS) && (
                    <div className="col-md-8 d-flex">
                        <StatsCards
                            data={{
                                total_orders,
                                total_users,
                                ...restStats,
                            }}
                            isLoading={loading}
                        />
                    </div>
                )}

                {hasPermission(email, OUTSTANDING_BALANCE) && (
                    <div className="col-md-4">
                        <div
                            className="wallet mb-4"
                            style={{
                                background: 'rgb(247, 149, 33)',
                                borderRadius: '5px',
                                color: 'rgb(255, 255, 255)',
                                padding: '20px 25px',
                                position: 'relative',
                            }}
                        >
                            <h5 className="mb-2">Outstanding Balance</h5>
                            <h3 className="mb-4 d-flex text-white">
                                ₦{numberWithCommas(balance?.holding)}
                            </h3>
                        </div>

                        {!walletBalanceLoading && (
                            <div
                                className="wallet"
                                style={{
                                    background: 'rgb(247, 149, 33)',
                                    borderRadius: '5px',
                                    color: 'rgb(255, 255, 255)',
                                    padding: '20px 25px',
                                    position: 'relative',
                                }}
                            >
                                <h5 className="mb-2">WATU Wallet Balance</h5>
                                <h3 className="mb-4 d-flex text-white">
                                    ₦
                                    {numberWithCommas(
                                        walletBalance?.data?.balance,
                                    )}
                                </h3>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </ThemeProvider>
    )
}

export default function Dashboard() {
    return <DashboardContent />
}
