import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { number } from 'prop-types'
import { toast } from 'react-hot-toast'
import { Button } from 'components/Button'
import { confirm } from 'components/ConfirmBox'
import { Status } from 'styles/utils'
import AvatarImg from 'assets/images/avatar.png'
import customerDetailsSlice from '../slice'
import {
    AvatarWrapper,
    Info,
    Name,
    TopSection,
    UserProfile,
} from './Overview.style'

function UserProfileContainer({ customerId }) {
    const dispatch = useDispatch()
    const userImage = AvatarImg

    const { actions } = customerDetailsSlice

    const { customer, penalty, error, penaltyLoading } = useSelector(state => ({
        customer: state.customerDetails.customer,
        penalty: state.customerDetails.penalty,
        error: state.customerDetails.error,
        penaltyLoading: state.customerDetails.penaltyLoading,
    }))

    useEffect(() => {
        if (penalty) {
            toast.success(penalty.message)
            dispatch(actions.getSingleCustomer(customerId))
            dispatch(actions.reset())
        }
    }, [penalty])

    useEffect(() => {
        if (error) {
            toast.error(error.message)
        }
    }, [penalty])

    const {
        firstname,
        lastname,
        email,
        phone,
        phone_temp,
        user_status,
        username,
    } = customer.user || {}

    const accountHolderName = `${firstname} ${lastname}`

    const handlePenalty = async type => {
        await dispatch(
            actions.penalizeCustomer({
                action: type,
                customerId,
            }),
        )
    }

    const blockAccount = () => {
        confirm({
            confirmText: 'Are you sure you want to block this account?',
            isLoading: penaltyLoading,
            onConfirm: async () => {
                handlePenalty('blocked')
            },
        })
    }

    const unBlockUser = () => {
        confirm({
            confirmText: 'Are you sure you want to unblock this account?',
            isLoading: penaltyLoading,
            onConfirm: async () => {
                handlePenalty('active')
            },
        })
    }

    // const restrictAccount = () => {
    //     confirm({
    //         confirmText: 'Are you sure you want to restrict this account?',
    //         isLoading: isBlocking,
    //         onConfirm: async () => {
    //             console.log('first')
    //         },
    //     })
    // }

    return (
        <>
            <TopSection>
                <UserProfile>
                    <AvatarWrapper>
                        <img src={userImage} alt="account-holder-avatar" />
                    </AvatarWrapper>
                    <div>
                        <Name className="mb-1 text-capitalize">
                            {accountHolderName}
                        </Name>
                        <Info className="text-capitalize">@{username}</Info>
                        <Info>{phone || phone_temp}</Info>
                        <Info className="mb-2">{email}</Info>
                        <Status status={user_status}>{user_status}</Status>
                    </div>
                </UserProfile>
                <div>
                    <div className="d-flex justify-content-end">
                        <>
                            {/* <span className="me-3">
                                <Button
                                    variant="secondary"
                                    onClick={restrictAccount} // Can login but can't transact
                                    isLoading={isBlocking}
                                >
                                    Restrict User
                                </Button>
                            </span> */}
                            {user_status === 'blocked' ? (
                                <Button
                                    variant="primary"
                                    onClick={unBlockUser}
                                    isLoading={penaltyLoading}
                                >
                                    Unblock User
                                </Button>
                            ) : (
                                <Button
                                    variant="danger"
                                    onClick={blockAccount}
                                    isLoading={penaltyLoading}
                                >
                                    Block User
                                </Button>
                            )}
                        </>
                    </div>
                </div>
            </TopSection>
        </>
    )
}

UserProfileContainer.propTypes = {
    customerId: number,
}
export default UserProfileContainer
