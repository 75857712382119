import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/product'

import rateSlice from '.'
const { actions } = rateSlice

export function* getRates(action) {
    try {
        const response = yield call(apis.getRates, action.payload)
        const { data } = response
        yield put(actions.getRatesSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getRatesError(data))
    }
}

export function* getSingleRate(action) {
    try {
        const response = yield call(apis.getSingleRate, action.payload)
        const { data } = response
        yield put(actions.getSingleRateSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getSingleRateError(data))
    }
}

export function* addRate(action) {
    try {
        const response = yield call(apis.addRate, action.payload)
        const { data } = response
        yield put(actions.addRateSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.addRateError(data))
    }
}

export function* updateRate(action) {
    try {
        const response = yield call(apis.updateRate, action.payload)
        const { data } = response
        yield put(actions.updateRateSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.updateRateError(data))
    }
}

export function* deleteRate(action) {
    try {
        const response = yield call(apis.deleteRate, action.payload)
        const { data } = response
        yield put(actions.deleteRateSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.deleteRateError(data))
    }
}

export function* rateSaga() {
    yield takeLatest(actions.getRates.type, getRates)
    yield takeLatest(actions.getSingleRate.type, getSingleRate)
    yield takeLatest(actions.addRate.type, addRate)
    yield takeLatest(actions.updateRate.type, updateRate)
    yield takeLatest(actions.deleteRate.type, deleteRate)
}
