import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { numberWithCommas } from 'utils/numbers'
import Table from 'components/Table'
import { Status } from 'styles/utils'
import FilterModal from './Feature/FilterModal'
import customerDetailsSlice from '../slice'

export default function Transactions({ customerId }) {
    const LIMIT = 15
    const { actions } = customerDetailsSlice
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [skip, setSkip] = useState(0)
    const [filter, setFilter] = useState({})
    const [filterPayload, setFilterPayload] = useState({})
    const [modalVisibility, setModalVisibility] = useState(false)

    const { transactions, loading } = useSelector(state => ({
        transactions: state.customerDetails.transactions,
        loading: state.customerDetails.loading,
    }))

    useEffect(() => {
        if (customerId) {
            const { status, type, parent_category, ...remainingFilter } = filter
            dispatch(
                actions.getCustomerTransactions({
                    customerId,
                    limit: LIMIT,
                    skip,
                    ...(status && { status }),
                    ...(type && { type }),
                    ...(parent_category && { parent_category }),
                    ...remainingFilter,
                }),
            )
        }
    }, [customerId, currentPage, skip, filterPayload])

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    const columns = [
        {
            field: 'name',
            headerName: 'Narration',
            renderCell: row => {
                return <span>{row.name}</span>
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            renderCell: row => {
                return <p className="d-flex">₦{numberWithCommas(row.amount)}</p>
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            renderCell: row => {
                return <Status status={row.type}>{row.type}</Status>
            },
        },
        {
            field: 'fund_disbursed',
            headerName: 'Funds Disbursed',
            renderCell: ({ fund_disbursed }) => {
                return (
                    <Status status={fund_disbursed ? 'on' : 'off'}>
                        {fund_disbursed ? 'Yes' : 'No'}
                    </Status>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: row => {
                return <Status status={row.status}>{row.status}</Status>
            },
        },
        {
            field: 'balance_before',
            headerName: 'Balance Before',
            renderCell: ({ balance_before }) => {
                return <p>₦{numberWithCommas(balance_before)}</p>
            },
        },
        {
            field: 'balance_after',
            headerName: 'Balance After',
            renderCell: ({ balance_after }) => {
                return <p>₦{numberWithCommas(balance_after)}</p>
            },
        },
        {
            field: 'parent_category',
            headerName: 'Category',
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return (
                    <p>
                        {dayjs(row.createdAt).format('MMMM D, YYYY | hh:mmA')}
                    </p>
                )
            },
        },
    ]

    const handleInput = value => {
        setFilter({
            ...filter,
            ...value,
        })
    }

    const onApplyFilter = () => {
        setFilterPayload(filter)
        setCurrentPage(1)
        setModalVisibility(false)
    }

    const onClearFilter = () => {
        setFilter({})
        setFilterPayload({})
        setModalVisibility(false)
    }

    const count = transactions.count

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-4">
                {
                    <p style={{ fontSize: '14px' }}>
                        {count && !loading ? `${count} results found!` : ''}{' '}
                    </p>
                }
                <Button
                    variant="outlined"
                    startIcon={<FilterAltRoundedIcon />}
                    onClick={() => setModalVisibility(true)}
                    style={{ height: '45px' }}
                >
                    Filter
                </Button>
            </div>
            <div style={{ borderTop: '1px solid #b8b8b8' }}>
                <Table
                    isLoading={loading}
                    currentPage={currentPage}
                    onPageChange={(e, page) => setCurrentPage(page)}
                    count={Math.ceil(count / LIMIT)}
                    columns={columns}
                    data={transactions?.results}
                />
            </div>

            {modalVisibility && (
                <FilterModal
                    onApplyFilter={onApplyFilter}
                    filter={filter}
                    onChange={handleInput}
                    onClearFilter={onClearFilter}
                    open={!!modalVisibility}
                    onClose={() => setModalVisibility(false)}
                />
            )}
        </div>
    )
}

Transactions.propTypes = {
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
