import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import Button from '@mui/material/Button'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { useDebounce } from 'use-debounce'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Status } from 'styles/utils'
import Table from 'components/Table'
import FilterModal from 'components/FilterModal'
import Modal from 'components/Modal'
import Input from 'components/Input'
import { MenuComponent } from 'components/Menu'
import { confirm } from 'components/ConfirmBox'
import { Button as ButtonComponent } from 'components/Button'
import { filters } from './utils'
import AddProduct from './Feature/AddProduct'
import productSlice from './slice'

export default function Customer() {
    const LIMIT = 15
    const { actions } = productSlice
    const dispatch = useDispatch()
    const [modalVisibility, setModalVisibility] = useState(false)
    const [filterModalVisibility, setFilterModalVisibility] = useState(false)
    const [addProductVisibility, setAddProductVisibility] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [skip, setSkip] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [searchTerm] = useDebounce(searchValue, 1500)
    const [filterData, setFilterData] = useState({})

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const { products, loading, error, updatedProduct } = useSelector(state => ({
        products: state.product.products,
        loading: state.product.loading,
        error: state.product.error,
        updatedProduct: state.product.updatedProduct,
    }))

    const getProducts = () => {
        const { type, ...rest } = filterData
        let payload = {
            ...(searchTerm && { name: searchTerm }),
            ...(type && { type: type.value }),
            ...rest,
        }
        dispatch(actions.getProducts({ limit: LIMIT, skip, ...payload }))
    }

    useEffect(() => {
        getProducts()
    }, [skip, searchTerm, filterData])

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    const handleProduct = ({ product_name, type, wallet_address, id }) => {
        if (addProductVisibility.edit) {
            dispatch(
                actions.updateProduct({
                    id,
                    name: product_name,
                    type: type.value,
                    ...(type.value === 'coin' && { wallet_address }),
                }),
            )
        } else {
            dispatch(
                actions.addProduct({
                    name: product_name,
                    type: type.value,
                    ...(type.value === 'coin' && { wallet_address }),
                }),
            )
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error?.message)
        }
    }, [error])

    useEffect(() => {
        if (updatedProduct) {
            toast.success(updatedProduct?.message)
            setAddProductVisibility(false)
            getProducts()
        }
    }, [updatedProduct])

    const deleteProduct = row => {
        confirm({
            confirmText: `Are you sure you want to delete this product (${row.name}) ?`,
            isLoading: false,
            onConfirm: async () => dispatch(actions.deleteProduct(row._id)),
        })
    }

    const options = [
        {
            name: 'Edit',
        },
    ]

    const count =
        products?.count > LIMIT ? Math.ceil(products?.count / LIMIT) : 1

    const columns = [
        {
            field: 'name',
            headerName: 'Product Name',
        },
        {
            field: 'type',
            headerName: 'Product Type',
            renderCell: ({ type }) => {
                return (
                    <Status status={type === 'card' ? 'success' : 'pending'}>
                        {type}
                    </Status>
                )
            },
        },
        {
            field: 'wallet_address',
            headerName: 'Wallet Address',
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return <p>{dayjs(row.createdAt).format('MMMM D, YYYY')}</p>
            },
        },
        {
            field: '',
            width: 0,
            renderCell: row => (
                <div>
                    <MenuComponent
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        open={open}
                        options={options}
                        icon={() => <MoreVertIcon />}
                        optionsFns={[
                            () =>
                                setAddProductVisibility({
                                    type: 'EDIT',
                                    edit: row,
                                }),
                            () => deleteProduct(row),
                            // place the callbacks in position corresponding to the options index
                        ]}
                    />
                </div>
            ),
        },
    ]

    const onCloseAddProduct = () => {
        setAddProductVisibility(false)
        dispatch(actions.resetUpdate())
    }
    return (
        <div>
            <div className="mb-4 d-flex justify-content-between align-items-cente">
                <div className="d-flex align-items-center">
                    <Typography variant="h3" gutterBottom>
                        Products
                    </Typography>
                    <p className="ms-3">{products?.count}</p>
                </div>

                <div className="d-flex align-items-center">
                    <div className="me-3" style={{ width: '350px' }}>
                        <Input
                            placeholder="Enter product name"
                            value={searchValue}
                            name="search"
                            type="text"
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </div>

                    <div className="d-flex align-items-center">
                        <Button
                            variant="outlined"
                            startIcon={<FilterAltRoundedIcon />}
                            onClick={() => setFilterModalVisibility(true)}
                            style={{ height: '45px', marginRight: '15px' }}
                        >
                            Filter
                        </Button>
                    </div>

                    <ButtonComponent
                        onClick={() => setAddProductVisibility({ type: 'ADD' })}
                    >
                        Add Product
                    </ButtonComponent>
                </div>
            </div>

            <Modal
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
            ></Modal>
            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={products?.data}
            />

            <FilterModal
                open={filterModalVisibility}
                onClose={() => setFilterModalVisibility(false)}
                title="Filter Orders"
                handleSubmit={setFilterData}
                filterData={filterData}
                filters={filters}
            />

            {addProductVisibility && (
                <AddProduct
                    open={addProductVisibility}
                    onClose={onCloseAddProduct}
                    handleOnSubmit={handleProduct}
                    getProducts={getProducts}
                />
            )}
        </div>
    )
}
