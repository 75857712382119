import { func, objectOf } from 'prop-types'
import { SelectInput } from 'components/Select'
import NumberInput from 'components/NumberInput'
import Input from 'components/Input'

export default function FilterInputRender({ onChange, value, item }) {
    const renderFilterInput = ({ label, key, type, options }) => {
        let template = null
        const inputProps = {
            key,
            label,
            name: key,
            placeholder: label,
            value: value[key],
        }

        switch (type) {
            case 'number':
                template = (
                    <NumberInput
                        {...inputProps}
                        onChange={({ floatValue }) =>
                            onChange({ [key]: floatValue })
                        }
                        prefix="hidden"
                    />
                )
                break

            case 'select':
                template = (
                    <SelectInput
                        {...inputProps}
                        onChange={val => onChange({ [key]: val })}
                        options={options}
                    />
                )
                break

            default:
                template = (
                    <Input
                        {...inputProps}
                        type={type}
                        onChange={e => onChange({ [key]: e.target.value })}
                    />
                )
                break
        }

        return template
    }

    return renderFilterInput(item)
}

FilterInputRender.propTypes = {
    onChange: func.isRequired,
    value: objectOf().isRequired,
    item: objectOf().isRequired,
}
