import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/product'

import productSlice from '.'
const { actions } = productSlice

export function* getProducts(action) {
    try {
        const response = yield call(apis.getProducts, action.payload)
        const { data } = response
        yield put(actions.getProductsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getProductsError(data))
    }
}

export function* getSingleProduct(action) {
    try {
        const response = yield call(apis.getSingleProduct, action.payload)
        const { data } = response
        yield put(actions.getSingleProductSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getSingleProductError(data))
    }
}

export function* addProduct(action) {
    try {
        const response = yield call(apis.addProduct, action.payload)
        const { data } = response
        yield put(actions.addProductSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.addProductError(data))
    }
}

export function* updateProduct(action) {
    try {
        const response = yield call(apis.updateProduct, action.payload)
        const { data } = response
        yield put(actions.updateProductSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.updateProductError(data))
    }
}

export function* deleteProduct(action) {
    try {
        const response = yield call(apis.deleteProduct, action.payload)
        const { data } = response
        yield put(actions.deleteProductSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.deleteProductError(data))
    }
}

export function* productSaga() {
    yield takeLatest(actions.addProduct.type, addProduct)
    yield takeLatest(actions.getProducts.type, getProducts)
    yield takeLatest(actions.getSingleProduct.type, getSingleProduct)
    yield takeLatest(actions.updateProduct.type, updateProduct)
    yield takeLatest(actions.deleteProduct.type, deleteProduct)
}
