import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Modal from 'components/Modal'
import { Button } from 'components/Button'
import FilterInputRender from 'components/FilterInputRender'

export default function FilterModal({
    open,
    onClose,
    filterData,
    handleSubmit,
    filters,
    title,
}) {
    const [value, setValue] = useState({})
    const [defaultValue, setDefaultValue] = useState({})

    useEffect(() => {
        if (filterData) {
            setValue(filterData)
            setDefaultValue(filterData)
        }
    }, [])

    function onChange(val) {
        setValue({
            ...value,
            ...val,
        })
    }

    const handleOnSubmit = () => {
        handleSubmit(value)
        onClose()
    }

    const clearFilter = () => {
        const resetValue = defaultValue || {}
        setValue(resetValue)
        handleSubmit(resetValue)
        onClose()
    }
    return (
        <Modal open={open} onClose={onClose} maxWidth="800px">
            {title && <DialogTitle>{title} </DialogTitle>}

            <DialogContent>
                <div className="row mt-3 mb-4">
                    {filters &&
                        filters.map(item => (
                            <div className="col-md-6 mb-4" key={item.key}>
                                <FilterInputRender
                                    onChange={onChange}
                                    value={value}
                                    item={item}
                                />
                            </div>
                        ))}
                </div>

                <div className="d-flex justify-content-between">
                    <Button onClick={clearFilter} variant="secondary">
                        Clear Filter
                    </Button>

                    <Button variant="contained" onClick={handleOnSubmit}>
                        Apply Filter
                    </Button>
                </div>
            </DialogContent>
        </Modal>
    )
}

FilterModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    filterData: PropTypes.object,
    filters: PropTypes.object,
    handleSubmit: PropTypes.func,
    title: PropTypes.sprint,
}
