import axiosCustom from 'utils/axiosCustom'
import { paramsObjectToQueryString } from 'utils/parser'

const apis = {
    getPaymentRequests: params =>
        axiosCustom(
            `/backoffice/finances/payment-requests${paramsObjectToQueryString(
                params,
            )}`,
        ),

    getPendingTransfers: params =>
        axiosCustom(
            `/backoffice/finances/pending-payment-transfers${paramsObjectToQueryString(
                params,
            )}`,
        ),

    updatePaymentRequests: ({ id, ...payload }) =>
        axiosCustom(`/backoffice/finances/payment-requests/${id}`, {
            method: 'PUT',
            body: payload,
        }),

    getDisbursements: params =>
        axiosCustom(
            `/backoffice/finances/disbursements${paramsObjectToQueryString(
                params,
            )}`,
        ),

    queryTransfer: payload =>
        axiosCustom(`/backoffice/finances/query-payment-transfer`, {
            method: 'POST',
            body: payload,
        }),

    refundFailedTransfer: payload =>
        axiosCustom(`/backoffice/finances/refund-failed-transfer`, {
            method: 'POST',
            body: payload,
        }),
}

export default apis
