import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    rates: null,
    rate: null,
    updatedRate: null,
    deletedRate: null,
    addingRate: false,
}

const rateSlice = createSlice({
    name: 'rate',
    initialState,
    reducers: {
        getRates: state => {
            state.loading = true
            state.error = false
        },
        getRatesSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.rates = action.payload
        },
        getRatesError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.rates = null
        },
        getSingleRate: state => {
            state.loading = true
            state.error = false
        },
        getSingleRateSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.rate = action.payload
        },
        getSingleRateError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.rate = null
        },
        addRate: state => {
            state.addingRate = true
            state.error = false
        },
        addRateSuccess: (state, action) => {
            state.addingRate = false
            state.error = false
            state.updatedRate = action.payload
        },
        addRateError: (state, action) => {
            state.addingRate = false
            state.error = action.payload
            state.updatedRate = null
        },
        updateRate: state => {
            state.addingRate = true
            state.error = false
        },
        updateRateSuccess: (state, action) => {
            state.addingRate = false
            state.error = false
            state.updatedRate = action.payload
        },
        updateRateError: (state, action) => {
            state.addingRate = false
            state.error = action.payload
            state.updatedRate = null
        },
        deleteRate: state => {
            state.loading = true
            state.error = false
        },
        deleteRateSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.updatedRate = action.payload
        },
        deleteRateError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.updatedRate = null
        },
        reset(state) {
            state.loading = false
            state.error = false
            state.rates = null
            state.rate = null
            state.deletedRate = null
            state.updatedRate = null
        },
        resetUpdate(state) {
            state.updatedRate = null
        },
    },
})

export default rateSlice
