import axiosCustom from 'utils/axiosCustom'
import { paramsObjectToQueryString } from 'utils/parser'

const apis = {
    getCustomers: params =>
        axiosCustom(
            `/backoffice/customers${paramsObjectToQueryString(params)}`,
        ),

    exportCustomers: params =>
        axiosCustom(
            `/backoffice/customers/customer-exports${paramsObjectToQueryString(
                params,
            )}`,
        ),

    getSingleCustomer: id => axiosCustom(`/backoffice/customers/${id}`),
    penalizeCustomer: (id, params) =>
        axiosCustom(`/backoffice/customers/penalty/${id}`, {
            data: params,
            method: 'POST',
        }),
    getCustomerTransactions: (id, params) => {
        return axiosCustom(
            `/backoffice/customers/transactions/${id}${paramsObjectToQueryString(
                params,
            )}`,
        )
    },
}

export default apis
