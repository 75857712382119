import axiosCustom from 'utils/axiosCustom'
import { paramsObjectToQueryString } from 'utils/parser'

const apis = {
    getOrders: params =>
        axiosCustom(`/backoffice/orders${paramsObjectToQueryString(params)}`),

    getSingleOrder: id => axiosCustom(`/backoffice/orders/${id}`),

    updateOrder: (id, payload) =>
        axiosCustom(`/backoffice/orders/${id}`, {
            method: 'PUT',
            body: payload,
            headers: { 'Content-Type': 'multipart/form-data' },
        }),
    creditOrder: (id, payload) =>
        axiosCustom(`/backoffice/orders/credit/${id}`, {
            method: 'PUT',
            body: payload,
        }),
}

export default apis
