import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/manager'

import managerSlice from '.'
const { actions } = managerSlice

export function* getManagers(action) {
    try {
        const response = yield call(apis.getManagers, action.payload)
        const { data } = response
        yield put(actions.getManagersSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getManagersError(data))
    }
}

export function* addManager(action) {
    try {
        const response = yield call(apis.addManager, action.payload)
        const { data } = response
        yield put(actions.addManagerSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.addManagerError(data))
    }
}

export function* managerSaga() {
    yield takeLatest(actions.getManagers.type, getManagers)
    yield takeLatest(actions.addManager.type, addManager)
}
