import axios from 'axios'
import { getToken, clearStorage } from 'utils/storage'

const logout = () => {
    clearStorage()
    window.location = '/'
}

async function axiosCustom(
    endpoint,
    { method = 'GET', data, body, ...customConfig } = {},
) {
    let url = ''
    const headers = { Accept: 'application/json' }

    // UNCOMMENT!!!!!!
    if (!process.env.REACT_APP_API_URL) {
        url = endpoint
    } else {
        url = `${
            process.env.REACT_APP_API_URL || 'http://localhost:8000/api'
        }${endpoint}`
    }

    const token = await getToken()

    const config = {
        method,
        data: data || body,
        ...customConfig,
        headers: {
            ...(token && { Authorization: token }),
            ...headers,
            ...customConfig.headers,
        },
    }

    return axios(url, config)
        .then(async response => {
            const data = response.data
            if (response.status === 201 || response.status === 200) {
                return Promise.resolve({
                    ok: response.status,
                    data,
                })
            }
        })
        .catch(e => {
            if (!e.response)
                return Promise.reject({
                    data: { message: 'An error occured, please try again' },
                })
            const { response } = e
            let message
            switch (response.status) {
                case 400:
                    message = 'Bad Request'
                    break
                case 401:
                    message = "You're not Authenticated. Kindly Login"
                    logout()
                    break
                case 403:
                    message = 'UnAuthorised User'
                    break
                case 404:
                    message = 'Resource not found'
                    break
                case 500:
                    message = 'Internal server error'
                    break
                default:
                    message = null
            }

            const errorRes = {
                ok: response.status,
                custom_message: message,
                data: response.data,
            }
            return Promise.reject(errorRes)
        })
}

export default axiosCustom
