import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import Button from '@mui/material/Button'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Status } from 'styles/utils'
import Table from 'components/Table'
import FilterModal from 'components/FilterModal'
import Modal from 'components/Modal'
import { MenuComponent } from 'components/Menu'
import { confirm } from 'components/ConfirmBox'
import { numberWithCommas } from 'utils/numbers'
import { Button as ButtonComponent } from 'components/Button'
import apis from 'services/product'
import { filters } from './utils'
import AddRate from './Feature/AddRate'
import rateSlice from './slice'
export default function Rates() {
    const LIMIT = 15
    const { actions } = rateSlice
    const dispatch = useDispatch()
    const [modalVisibility, setModalVisibility] = useState(false)
    const [filterModalVisibility, setFilterModalVisibility] = useState(false)
    const [addRateVisaibility, setAddRateVisibility] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [products, setProducts] = useState([])
    const [skip, setSkip] = useState(0)
    const [filterData, setFilterData] = useState({})

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const { rates, loading, error, updatedRate } = useSelector(state => ({
        rates: state.rate.rates,
        loading: state.rate.loading,
        error: state.rate.error,
        updatedRate: state.rate.updatedRate,
    }))

    const getProducts = async () => {
        try {
            const response = await apis.getProducts()
            setProducts(
                response?.data?.data?.map(({ name, _id }) => ({
                    label: name,
                    value: _id,
                })),
            )
        } catch (e) {
            console.log(e)
        }
    }

    const getSelectPayload = (data, product) => {
        const { product_id, featured, country_code, ...rest } = data
        const payload = {
            ...rest,
            ...(product_id && { [product || 'product']: product_id?.value }),
            ...(featured && { featured: featured?.value }),
            ...(country_code && { country_code: country_code?.value }),
        }
        return payload
    }

    const getRates = () => {
        const payload = getSelectPayload(filterData, 'product')
        dispatch(
            actions.getRates({
                limit: LIMIT,
                skip,
                order: 'desc',
                ...payload,
            }),
        )
    }

    useEffect(() => {
        if (updatedRate) {
            toast.success(updatedRate?.message)
            setAddRateVisibility(false)
            getRates()
        }
    }, [updatedRate])

    useEffect(() => {
        getRates()
    }, [skip, filterData])

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    const handleRate = data => {
        if (addRateVisaibility.edit) {
            const payload = getSelectPayload(data, 'product_id')
            delete payload.product_id
            dispatch(actions.updateRate(payload))
        } else {
            const payload = getSelectPayload(data, 'product_id')
            dispatch(actions.addRate(payload))
        }
    }

    const deleteRate = row => {
        confirm({
            confirmText: (
                <div>
                    {' '}
                    Are you sure you want to delete this rate for{' '}
                    <span className="text-capitalize">
                        {' '}
                        {row?.product_id?.name}
                    </span>
                    : ({row?.high} - {row?.low}) ?
                </div>
            ),
            isLoading: false,
            onConfirm: async () => dispatch(actions.deleteRate(row?._id)),
        })
    }

    useEffect(() => {
        if (error) {
            console.log(error)
            toast.error(error?.message)
        }
    }, [error])

    useEffect(() => {
        getProducts()
    }, [])

    const options = [
        {
            name: 'Edit',
        },
        {
            name: 'Delete',
            color: 'red',
        },
    ]

    const count = rates?.count > LIMIT ? Math.ceil(rates?.count / LIMIT) : 1

    const getFilters = () =>
        filters.map(item => {
            if (item.key === 'product_id')
                return {
                    ...item,
                    options: products,
                }

            return item
        })

    const columns = [
        {
            field: 'product_id.name',
            headerName: 'Product Name',
            renderCell: ({ product_id }) => {
                return <p>{product_id.name}</p>
            },
        },
        {
            field: 'country_code',
            headerName: 'Country',
            renderCell: row => {
                return <p className="text-uppercase">{row.country_code}</p>
            },
        },
        {
            field: 'low',
            headerName: 'Low',
        },
        {
            field: 'high',
            headerName: 'High',
        },

        {
            field: 'value',
            headerName: 'Value',
            renderCell: row => {
                return (
                    <p className="text-uppercase">
                        ₦{numberWithCommas(row.value)}
                    </p>
                )
            },
        },
        {
            field: 'featured',
            headerName: 'Featured',
            renderCell: ({ featured }) => {
                return (
                    <Status status={featured ? 'success' : 'pending'}>
                        {featured ? 'Yes' : 'No'}
                    </Status>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return <p>{dayjs(row.createdAt).format('MMMM D, YYYY')}</p>
            },
        },
        {
            field: '',
            width: 0,
            renderCell: row => (
                <div>
                    <MenuComponent
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        open={open}
                        options={options}
                        icon={() => <MoreVertIcon />}
                        optionsFns={[
                            () =>
                                setAddRateVisibility({
                                    type: 'EDIT',
                                    edit: row,
                                }),
                            () => deleteRate(row),
                            // place the callbacks in position corresponding to the options index
                        ]}
                    />
                </div>
            ),
        },
    ]

    return (
        <div>
            <div className="mb-4 d-flex justify-content-between align-items-cente">
                <div className="d-flex align-items-center">
                    <Typography variant="h3" gutterBottom>
                        Rates
                    </Typography>
                    <p className="ms-3">{rates?.count}</p>
                </div>

                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <Button
                            variant="outlined"
                            startIcon={<FilterAltRoundedIcon />}
                            onClick={() => setFilterModalVisibility(true)}
                            style={{ height: '45px', marginRight: '15px' }}
                        >
                            Filter
                        </Button>
                    </div>

                    <ButtonComponent
                        onClick={() => setAddRateVisibility({ type: 'ADD' })}
                    >
                        Add Rate
                    </ButtonComponent>
                </div>
            </div>

            <Modal
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
            ></Modal>
            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={rates?.data}
            />

            <FilterModal
                open={filterModalVisibility}
                onClose={() => setFilterModalVisibility(false)}
                title="Filter Rates"
                handleSubmit={setFilterData}
                filterData={filterData}
                filters={getFilters()}
            />

            {addRateVisaibility && (
                <AddRate
                    products={products}
                    open={addRateVisaibility}
                    onClose={() => setAddRateVisibility(false)}
                    handleOnSubmit={handleRate}
                />
            )}
        </div>
    )
}
