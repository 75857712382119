import { useState, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { confirm } from 'components/ConfirmBox'
import Table from 'components/Table'
import { Status } from 'styles/utils'
import { numberWithCommas } from 'utils/numbers'
import FilterModal from 'components/FilterModal'
import { MenuComponent } from 'components/Menu'
import { LoadingModal } from 'components/LoadingModal'
import { getBank } from 'utils/bank'
import { filters } from '../../utils'
import paymentSlice from '../../slice'

export default function Payouts({
    filterModalVisibility,
    setFilterModalVisibility,
    refreshWalletBalance,
}) {
    const bankUrl =
        'https://api.ravepay.co/v2/banks/NG?public_key=FLWPUBK-0ce0fb97ef10b0bf9e18fb8fb0d6506b-X'
    const LIMIT = 15
    const { actions } = paymentSlice
    const dispatch = useDispatch()
    const [banks, setBanks] = useState([])
    const [skip, setSkip] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [filterData, setFilterData] = useState({})
    const [processingPayment, setProcessingPayment] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const { payments, updating, loading, updatedPayment, paymentError } =
        useSelector(state => ({
            payments: state.payment.payments,
            loading: state.payment.loading,
            updating: state.payment.updating,
            updatedPayment: state.payment.updatedPayment,
            paymentError: state.payment.paymentError,
        }))

    const fetchBankList = async () => {
        try {
            const banks = await axios(bankUrl)
            setBanks(banks.data?.data?.Banks)
        } catch (e) {
            toast.error('Could not get list of banks')
        }
    }

    const getPaymentRequests = () => {
        const { status, ...rest } = filterData
        let payload = {
            ...rest,
            ...(status && { status: status.value }),
        }
        dispatch(actions.getPaymentRequests({ limit: LIMIT, skip, ...payload }))
    }

    const handlePaymentApproval = (id, status) => {
        confirm({
            confirmText: `Are you sure you want to ${status} this payment?`,
            isLoading: false,
            onConfirm: async () => {
                setProcessingPayment(true)
                dispatch(
                    actions.updatePaymentRequests({
                        id,
                        status: status === 'approve' ? 'success' : 'declined',
                    }),
                )
                if (status === 'approve') {
                    refreshWalletBalance()
                }
            },
        })
    }

    useEffect(() => {
        fetchBankList()

        return () => {
            dispatch(actions.reset())
        }
    }, [])

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    useEffect(() => {
        getPaymentRequests()
    }, [skip, filterData])

    const options = [
        {
            name: 'Approve',
        },
        {
            name: 'Decline',
        },
    ]

    const onCompletePayment = () => {
        getPaymentRequests()
        dispatch(actions.resetSelect({ updatedPayment: null }))
        setProcessingPayment(false)
    }

    const onError = () => {
        getPaymentRequests()
        dispatch(actions.resetSelect({ paymentError: null }))
        setProcessingPayment(false)
    }

    const columns = [
        {
            field: '_id',
            headerName: 'Payment ID',
            renderCell: row => {
                return <p className="text-lowercase">{row._id}</p>
            },
        },
        {
            field: 'user',
            headerName: 'User Email',
            renderCell: ({ user }) => {
                return <p className="text-lowercase">{user.email}</p>
            },
        },
        {
            field: 'account_no',
            headerName: 'Account No',
        },
        {
            field: 'bank',
            headerName: 'Bank',
            renderCell: row => {
                return <p>{getBank(row.bank, banks)}</p>
            },
        },
        {
            field: 'account_name',
            headerName: 'Account Name',
        },
        {
            field: 'amount',
            headerName: 'Requested Amount',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.amount)}</p>
            },
        },
        {
            field: 'balance',
            headerName: 'Balance',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.balance)}</p>
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: ({ status }) => {
                return <Status status={status}>{status}</Status>
            },
        },
        {
            field: 'transfer_status',
            headerName: 'Transfer Status',
            renderCell: ({ transfer_status }) => {
                return (
                    <Status status={transfer_status}>{transfer_status}</Status>
                )
            },
        },
        {
            field: 'attended_by.email',
            headerName: 'Attended By ',
            renderCell: ({ attended_by }) => {
                return (
                    <span>
                        {attended_by?.firstname
                            ? `${attended_by?.firstname} ${attended_by?.lastname}`
                            : attended_by?.username}
                        {!attended_by && ' - '}
                    </span>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return (
                    <p>
                        {dayjs(row.createdAt).format('MMMM D, YYYY')} {' | '}
                        {dayjs(row.createdAt).format('hh:mmA')}
                    </p>
                )
            },
        },
        {
            field: '',
            width: 0,
            renderCell: row =>
                row.status === 'pending' ? (
                    <div>
                        <MenuComponent
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            open={open}
                            options={options}
                            icon={() => <MoreVertIcon />}
                            optionsFns={[
                                () => handlePaymentApproval(row._id, 'approve'),
                                () => handlePaymentApproval(row._id, 'decline'),
                                // place the callbacks in position corresponding to the options index
                            ]}
                        />
                    </div>
                ) : null,
        },
    ]

    const handleFilter = data => {
        setFilterData(data)
        setCurrentPage(1)
    }

    const count =
        payments?.count > LIMIT ? Math.ceil(payments?.count / LIMIT) : 1

    return (
        <div>
            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={payments?.data}
            />

            <FilterModal
                open={filterModalVisibility}
                onClose={() => setFilterModalVisibility(false)}
                title="Filter Payments"
                handleSubmit={handleFilter}
                filterData={filterData}
                filters={filters}
            />

            {processingPayment && (
                <LoadingModal
                    description="Payment processing"
                    loading={updating}
                    {...(updatedPayment && {
                        success: {
                            message: updatedPayment?.message,
                        },
                    })}
                    {...(paymentError && {
                        error: {
                            message: paymentError?.message,
                        },
                    })}
                    onSuccess={onCompletePayment}
                    onError={onError}
                    onClose={() => null}
                />
            )}
        </div>
    )
}

Payouts.propTypes = {
    filterModalVisibility: PropTypes.bool,
    setFilterModalVisibility: PropTypes.func,
    refreshWalletBalance: PropTypes.func,
}
