import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { useDebounce } from 'use-debounce'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Status } from 'styles/utils'
import Table from 'components/Table'
import Modal from 'components/Modal'
import Input from 'components/Input'
import { MenuComponent } from 'components/Menu'
import { confirm } from 'components/ConfirmBox'
import { isEmail } from 'utils/validations'
import { Button as ButtonComponent } from 'components/Button'
import customerDetailsSlice from 'components/CustomerDetails/slice'
import AddNewManager from './Feature/AddNewManager'
import managerSlice from './slice'

export default function Managers() {
    const LIMIT = 15
    const { actions } = managerSlice
    const dispatch = useDispatch()
    const [modalVisibility, setModalVisibility] = useState(false)
    const [addManagerVisibility, setAddManagerVisibility] = useState(false)
    const [activeManagers, setActiveManagers] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [skip, setSkip] = useState(0)
    const { actions: customerActions } = customerDetailsSlice
    const [searchValue, setSearchValue] = useState('')
    const [searchTerm] = useDebounce(searchValue, 1500)

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const { managers, loading, error, addManagerSuccess, penalty } =
        useSelector(state => ({
            managers: state.manager.managers,
            loading: state.manager.loading,
            error: state.manager.error,
            addManagerSuccess: state.manager.addManager,
            penalty: state.customerDetails.penalty,
        }))

    const handleSearchType = search => {
        if (isEmail(search))
            return {
                email: search,
            }

        return {
            username: search,
        }
    }

    const getManagers = () => {
        let payload = {
            ...(activeManagers && { user_status: 'active' }),
            ...(searchTerm && handleSearchType(searchTerm)),
        }
        dispatch(actions.getManagers({ limit: LIMIT, skip, ...payload }))
    }

    useEffect(() => {
        getManagers()
    }, [skip, activeManagers, searchTerm])

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    const addNewManager = value => {
        dispatch(
            actions.addManager({ ...value, adminRole: value.adminRole.value }),
        )
    }

    useEffect(() => {
        if (error) {
            toast.error(error?.message)
        }
    }, [error])

    useEffect(() => {
        if (addManagerSuccess) {
            toast.success(addManagerSuccess?.message)
            setAddManagerVisibility(false)
            getManagers()
        }
    }, [addManagerSuccess])

    useEffect(() => {
        if (penalty) {
            toast.success(penalty?.message)
            getManagers()
        }
    }, [penalty])

    useEffect(() => {
        dispatch(customerActions.reset())
    }, [])

    const handlePenalty = async (type, id) => {
        await dispatch(
            customerActions.penalizeCustomer({
                action: type,
                customerId: id,
            }),
        )
    }

    const handleDeactivateAdmin = row => {
        confirm({
            confirmText: 'Are you sure you want to block this account?',
            isLoading: false,
            onConfirm: async () => {
                handlePenalty('blocked', row._id)
            },
        })
    }

    const reactiveAdmin = row => {
        confirm({
            confirmText: 'Are you sure you want to unblock this account?',
            isLoading: false,
            onConfirm: async () => {
                handlePenalty('active', row._id)
            },
        })
    }

    const getOptions = row => {
        return [
            {
                name:
                    row.user_status === 'active' ? 'Deactivate' : 'Reactivate',
            },
        ]
    }

    const count =
        managers?.count > LIMIT ? Math.ceil(managers?.count / LIMIT) : 1

    const columns = [
        {
            field: 'username',
            headerName: 'Username',
        },
        {
            field: 'name',
            headerName: 'Name',
            renderCell: item => {
                return (
                    <p>
                        {item.firstname
                            ? `${item.firstname} ${item.lastname}`
                            : ' - '}
                    </p>
                )
            },
        },
        {
            field: 'email',
            headerName: 'Email Address',
            transform: 'lowercase',
        },
        {
            field: 'user_status',
            headerName: 'Account Status',
            renderCell: ({ user_status }) => {
                return <Status status={user_status}>{user_status}</Status>
            },
        },
        {
            field: 'adminRole',
            headerName: 'Role',
        },

        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return <p>{dayjs(row.createdAt).format('MMMM D, YYYY')}</p>
            },
        },
        {
            field: '',
            width: 0,
            renderCell: row => (
                <div>
                    <MenuComponent
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        open={open}
                        options={getOptions(row)}
                        icon={() => <MoreVertIcon />}
                        optionsFns={[
                            () =>
                                row.user_status === 'active'
                                    ? handleDeactivateAdmin(row)
                                    : reactiveAdmin(row),
                            // place the callbacks in position corresponding to the options index
                        ]}
                    />
                </div>
            ),
        },
    ]

    return (
        <div>
            <div className="mb-4 d-flex justify-content-between align-items-cente">
                <div className="d-flex align-items-center">
                    <Typography variant="h3" gutterBottom>
                        Managers
                    </Typography>
                    <p className="ms-3">{managers?.count}</p>
                </div>

                <div className="d-flex align-items-center">
                    <div className="me-3" style={{ width: '350px' }}>
                        <Input
                            placeholder="Enter username or email address"
                            value={searchValue}
                            name="search"
                            type="text"
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </div>

                    <ButtonComponent
                        onClick={() => setAddManagerVisibility(true)}
                    >
                        Add Manager
                    </ButtonComponent>
                </div>
            </div>

            <Modal
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
            ></Modal>
            <div className="d-flex align-item-center">
                <input
                    name="active-managers"
                    type="checkbox"
                    className="me-2"
                    checked={activeManagers}
                    onChange={() => setActiveManagers(!activeManagers)}
                />
                <label htmlFor="active-managers">
                    Show active managers only
                </label>
            </div>
            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={managers?.users}
            />

            {addManagerVisibility && (
                <AddNewManager
                    open={addManagerVisibility}
                    onClose={() => setAddManagerVisibility(false)}
                    handleOnSubmit={addNewManager}
                />
            )}
        </div>
    )
}
