import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { numberWithCommas } from 'utils/numbers'
import dashboardSlice from 'pages/Dashboard/slice'
import Payouts from './Feature/Payouts'
import Credits from './Feature/Credits'
import PendingTransfers from './Feature/PendingTransfers'

export default function Payments() {
    const { actions: dashboardActions } = dashboardSlice
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('payouts')
    const [filterModalVisibility, setFilterModalVisibility] = useState(false)

    const { payments, error, walletBalance } = useSelector(state => ({
        payments: state.payment.payments,
        loading: state.payment.loading,
        error: state.payment.error,
        walletBalance: state.dashboard.walletBalance,
    }))

    const handleGetBalance = () => dispatch(dashboardActions.getWalletBalance())

    useEffect(() => {
        handleGetBalance()
    }, [])

    useEffect(() => {
        if (error) {
            toast.error(error?.message)
        }
    }, [error])

    const handleTab = key => setActiveTab(key)

    const tabs = [
        {
            name: 'Withdrawal Requests',
            key: 'payouts',
        },
        {
            name: 'Pending Transfers',
            key: 'pending_transfers',
        },
        {
            name: 'Credit Activities',
            key: 'credit',
        },
    ]

    return (
        <div>
            <div className="mb-4 d-flex justify-content-between align-items-cente">
                <div className="d-flex align-items-center">
                    <Typography variant="h3" gutterBottom>
                        Payments
                    </Typography>
                    <p className="ms-3">{payments?.count}</p>
                </div>

                <div className="d-flex align-items-center">
                    <Button
                        variant="outlined"
                        startIcon={<FilterAltRoundedIcon />}
                        onClick={() => setFilterModalVisibility(true)}
                        style={{ height: '45px', marginRight: '15px' }}
                    >
                        Filter
                    </Button>
                </div>
            </div>

            {walletBalance?.data && (
                <div className="d-flex align-items-center mb-4">
                    <Typography variant="h6" gutterBottom>
                        WATU Wallet Balance: ₦
                        {numberWithCommas(walletBalance?.data?.balance)}
                    </Typography>
                    <span
                        className="ms-3 pb-1"
                        style={{ cursor: 'pointer' }}
                        onClick={handleGetBalance}
                    >
                        refresh
                    </span>
                </div>
            )}

            <div className="mb-4 d-flex">
                {tabs.map(item => (
                    <h6
                        key={item.key}
                        className={classNames('me-3 cursor-pointer ', {
                            'text-info': activeTab === item.key,
                        })}
                        onClick={() => handleTab(item.key)}
                    >
                        {item.name}
                    </h6>
                ))}
            </div>

            {activeTab === 'payouts' && (
                <Payouts
                    filterModalVisibility={filterModalVisibility}
                    setFilterModalVisibility={setFilterModalVisibility}
                    refreshWalletBalance={handleGetBalance}
                />
            )}

            {activeTab === 'pending_transfers' && (
                <PendingTransfers
                    filterModalVisibility={filterModalVisibility}
                    setFilterModalVisibility={setFilterModalVisibility}
                />
            )}

            {activeTab === 'credit' && (
                <Credits
                    filterModalVisibility={filterModalVisibility}
                    setFilterModalVisibility={setFilterModalVisibility}
                />
            )}
        </div>
    )
}
