import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/ambassdor'

import ambassadorSlice from '.'
const { actions } = ambassadorSlice

export function* getAmbassadors(action) {
    try {
        const response = yield call(apis.getAmbassadors, action.payload)
        const { data } = response
        yield put(actions.getAmbassadorsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getAmbassadorsError(data))
    }
}

export function* exportAmbassadors(action) {
    try {
        const response = yield call(apis.exportAmbassadors, action.payload)
        const { data } = response
        yield put(actions.exportAmbassadorsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.exportAmbassadorsError(data))
    }
}

export function* ambassadorSaga() {
    yield takeLatest(actions.getAmbassadors.type, getAmbassadors)
    yield takeLatest(actions.exportAmbassadors.type, exportAmbassadors)
}
