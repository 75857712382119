import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    orderLoading: false,
    creditLoading: false,
    updateLoading: false,
    error: false,
    orders: null,
    order: {},
    updatedOrder: null,
    creditOrder: null,
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        getOrders: state => {
            state.loading = true
            state.error = false
        },
        getOrdersSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.orders = action.payload
        },
        getOrdersError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.orders = null
        },
        getSingleOrder: state => {
            state.orderLoading = true
            state.error = false
        },
        getSingleOrderSuccess: (state, action) => {
            state.orderLoading = false
            state.error = false
            state.order = action.payload
        },
        getSingleOrderError: (state, action) => {
            state.orderLoading = false
            state.error = action.payload
            state.order = null
        },
        updateOrder: state => {
            state.updateLoading = true
            state.error = false
        },
        updateOrderSuccess: (state, action) => {
            state.updateLoading = false
            state.error = false
            state.updatedOrder = action.payload
        },
        updateOrderError: (state, action) => {
            state.updateLoading = false
            state.error = action.payload
            state.updatedOrder = null
        },
        creditOrder: state => {
            state.creditLoading = true
            state.error = false
        },
        creditOrderSuccess: (state, action) => {
            state.creditLoading = false
            state.error = false
            state.creditOrder = action.payload
        },
        creditOrderError: (state, action) => {
            state.creditLoading = false
            state.error = action.payload
            state.creditOrder = null
        },
        resetUpdatedOrder: state => {
            state.updatedOrder = null
            state.creditOrder = null
        },
        reset: state => {
            state.loading = false
            state.error = false
            state.payments = null
            state.updatedOrder = null
            state.creditOrder = null
        },
    },
})

export default orderSlice
