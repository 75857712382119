import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Input from 'components/Input'
import { Button } from 'components/Button'
import Modals from 'components/Modals'
import NumberInput from 'components/NumberInput'
import { SelectInput } from 'components/Select'
import {
    transactionStatuses,
    transactionTypes,
    transactionCategories,
} from 'utils/transactions'

export default function FilterModal({
    onApplyFilter,
    filter,
    onChange,
    onClearFilter,
    open,
    onClose,
}) {
    return (
        <Modals open={open} onClose={onClose}>
            <DialogTitle>Filter Transactions </DialogTitle>
            <DialogContent>
                <div className="row mt-3 mb-4">
                    <div className="col-lg-6 mb-4">
                        <Input
                            placeholder="Start Date"
                            value={filter.start_date}
                            name="start_date"
                            label="Start Date"
                            type="date"
                            onChange={e =>
                                onChange({ start_date: e.target.value })
                            }
                        />
                    </div>

                    <div className="col-lg-6 mb-4">
                        <Input
                            placeholder="End Date"
                            value={filter.end_date}
                            label="End Date"
                            name="enc_date"
                            type="date"
                            onChange={e =>
                                onChange({ end_date: e.target.value })
                            }
                        />
                    </div>

                    <div className="col-lg-6 mb-4">
                        <NumberInput
                            placeholder="Enter minimum value"
                            value={filter.amount_gt}
                            label="Amount above"
                            currency="NGN"
                            onChange={valueObject =>
                                onChange({ amount_gt: valueObject.floatValue })
                            }
                        />
                    </div>

                    <div className="col-lg-6 mb-4">
                        <NumberInput
                            placeholder="Enter maximum value"
                            value={filter.amount_lt}
                            label="Amount below"
                            currency="NGN"
                            onChange={valueObject =>
                                onChange({ amount_lt: valueObject.floatValue })
                            }
                        />
                    </div>
                    <div className="col-lg-6 mb-4">
                        <SelectInput
                            placeholder="Select Type"
                            value={transactionTypes.find(
                                item => item.value === filter.type,
                            )}
                            label="Transaction Type"
                            onChange={value => onChange({ type: value.value })}
                            options={transactionTypes}
                        />
                    </div>

                    <div className="col-lg-6 mb-4">
                        <SelectInput
                            placeholder="Select Status"
                            value={transactionStatuses.find(
                                item => item.value === filter.status,
                            )}
                            label="Transaction Status"
                            onChange={value =>
                                onChange({ status: value.value })
                            }
                            options={transactionStatuses}
                        />
                    </div>
                    <div className="col-lg-6">
                        <SelectInput
                            placeholder="Select Category"
                            value={transactionCategories.find(
                                item => item.value === filter.parent_category,
                            )}
                            label="Transaction Category"
                            onChange={value =>
                                onChange({ parent_category: value.value })
                            }
                            options={transactionCategories}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-between">
                    <Button onClick={onClearFilter} variant="secondary">
                        Clear Filter
                    </Button>

                    <Button variant="contained" onClick={onApplyFilter}>
                        Apply Filter
                    </Button>
                </div>
            </DialogContent>
        </Modals>
    )
}

FilterModal.propTypes = {
    onApplyFilter: PropTypes.func,
    filter: PropTypes.object,
    onClearFilter: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}
