import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Input from 'components/Input'
import { Button } from 'components/Button'
// import { SelectInput } from 'components/Select'
import NumberInput from 'components/NumberInput'
// import { userStatusOptions } from 'utils/userRoles'

export default function FilterModal({
    onApplyFilter,
    filter,
    onChange,
    onClearFilter,
}) {
    return (
        <div>
            <DialogTitle>Filter Customer </DialogTitle>
            <DialogContent>
                <div className="row mt-3 mb-4">
                    <div className="col-lg-6 mb-4">
                        <Input
                            placeholder="Start Date"
                            value={filter.start_date}
                            name="start_date"
                            label="Start Date"
                            type="date"
                            onChange={e =>
                                onChange({ start_date: e.target.value })
                            }
                        />
                    </div>

                    <div className="col-lg-6 mb-4">
                        <Input
                            placeholder="End Date"
                            value={filter.end_date}
                            label="End Date"
                            name="end_date"
                            type="date"
                            onChange={e =>
                                onChange({ end_date: e.target.value })
                            }
                        />
                    </div>

                    <div className="col-lg-6">
                        <NumberInput
                            placeholder="Enter minimum value"
                            value={filter.balance_gt}
                            label="Balance above"
                            currency="NGN"
                            onChange={valueObject =>
                                onChange({ balance_gt: valueObject.floatValue })
                            }
                        />
                    </div>

                    <div className="col-lg-6">
                        <NumberInput
                            placeholder="Enter maximum value"
                            value={filter.balance_lt}
                            label="Balance below"
                            currency="NGN"
                            onChange={valueObject =>
                                onChange({ balance_lt: valueObject.floatValue })
                            }
                        />
                    </div>

                    {/* <div className="col-lg-6 mb-4">
                        <SelectInput
                            placeholder="Select User Status"
                            value={userStatusOptions.find(
                                item =>
                                    item.value === filter.user_status?.value,
                            )}
                            label="User Status"
                            name="adminRole"
                            onChange={value =>
                                onChange({
                                    user_status: value,
                                })
                            }
                            options={userStatusOptions}
                        />
                    </div> */}
                </div>

                <div className="d-flex justify-content-between">
                    <Button onClick={onClearFilter} variant="secondary">
                        Clear Filter
                    </Button>

                    <Button variant="contained" onClick={onApplyFilter}>
                        Apply Filter
                    </Button>
                </div>
            </DialogContent>
        </div>
    )
}

FilterModal.propTypes = {
    onApplyFilter: PropTypes.func,
    filter: PropTypes.object,
    onClearFilter: PropTypes.func,
}
