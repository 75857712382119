import { getCountryList } from 'utils/constant'

export const filters = [
    {
        label: 'Start Date',
        key: 'start_date',
        type: 'date',
    },
    {
        label: 'End Date',
        key: 'end_date',
        type: 'date',
    },
    {
        label: 'Product',
        key: 'product_id',
        type: 'select',
        options: [],
    },
    {
        label: 'Country',
        key: 'country_code',
        type: 'select',
        options: getCountryList(),
    },
    {
        label: 'Featured',
        key: 'featured',
        type: 'select',
        options: [
            {
                label: 'All',
                value: null,
            },
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
    },
]
