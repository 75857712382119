import styled from 'styled-components'

// interface TableWrapperProps {
//     transform?: string
// }

export const TableWrapper = styled.div`
    .MuiTableCell-root {
        text-transform: capitalize;
    }
`
