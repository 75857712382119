import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Div = styled.div`
    ${({ fullWidth }) => fullWidth && 'width: 100%;'}
    position: relative;
    display: inline-block;
    width: 100%;
`

export const Label = styled.label`
    pointer-events: none;
    color: #435a70;
    white-space: nowrap;
    margin-bottom: 0.3rem;
    font-size: 14px;
    display: block;
    font-weight: 400;
    color: ${({ theme, error }) =>
        error ? theme.colors?.redError : theme.colors?.black};
`

export const AdornmentWrapperDiv = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: absolute;
    padding-left: 10px;
    margin-right: 10px;

    &.end {
        right: 0px;
        top: 25%;
    }
`

const inputFieldCss = css`
    width: 100%;
    border: 1px solid
        ${({ theme, error }) =>
            error ? theme.colors?.redError : theme.colors?.grey};
    outline: none;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    appearance: none;
    border-radius: 0;
    padding: 0 ${({ startAdornment }) => (startAdornment ? '1.5rem' : '1rem')};
    padding-left: ${({ startAdornment }) => (startAdornment ? '4rem' : '1rem')};
    box-sizing: border-box;
    height: 48px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors?.white};

    &::-ms-clear {
        display: none;
    }

    &::-ms-expand {
        display: none;
    }

    &:-moz-ui-invalid {
        box-shadow: none;
    }

    &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #808080;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s;
        -webkit-text-fill-color: #000 !important;
    }

    &:disabled {
        background-color: hsl(0, 0%, 95%);
    }
`

export const InputField = styled.input`
    ${inputFieldCss};
    border: 1px solid
        ${({ theme, error }) =>
            error ? theme.colors?.redError : theme.colors?.grey};
    padding: 0 ${({ startAdornment }) => (startAdornment ? '1.5rem' : '1rem')};
    padding-left: ${({ startAdornment }) => (startAdornment ? '4rem' : '1rem')};
    background-color: ${({ theme }) => theme.colors?.white};
`

export const ErrorP = styled(motion.p)`
    margin: 0.1rem 0 0 0;
    color: ${({ theme }) => theme.colors?.redError};
    font-size: 14px;
    letter-spacing: -0.01rem;
    font-weight: 400;

    ${({ easyFlow }) =>
        easyFlow &&
        css`
            margin: 0;
            position: absolute;
            left: 65px;
            bottom: -21px;
            font-size: 11.5px;
        `}
`

export const PasswordControlButton = styled.button`
    background: none;
    border: none;
    width: 30px;
    overflow: hidden;

    .eye-icon:focus {
        outline: none;
    }
`

export const NumberInputField = styled.div`
    width: 100%;
    .number-format {
        ${inputFieldCss}
    }
`
export const InputFieldWrapperDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`
