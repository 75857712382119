import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/misc'

import dashboardSlice from '.'
const { actions } = dashboardSlice

export function* getDashboardStats(action) {
    try {
        const response = yield call(apis.getDashboardStats, action.payload)
        const { data } = response
        yield put(actions.getDashboardStatsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getDashboardStatsError(data))
    }
}
export function* getOutstandingBalance(action) {
    try {
        const response = yield call(apis.getOutstandingBalance, action.payload)
        const { data } = response
        yield put(actions.getOutstandingBalanceSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getOutstandingBalanceError(data))
    }
}

export function* getWalletBalance(action) {
    try {
        const response = yield call(apis.getWalletBalance, action.payload)
        const { data } = response
        yield put(actions.getWalletBalanceSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getWalletBalanceError(data))
    }
}

export function* dashboardSaga() {
    yield takeLatest(actions.getDashboardStats.type, getDashboardStats)
    yield takeLatest(actions.getOutstandingBalance.type, getOutstandingBalance)
    yield takeLatest(actions.getWalletBalance.type, getWalletBalance)
}
