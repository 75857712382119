import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import Modal from 'components/Modals'
import Input from 'components/Input'
import { adminRolesOptions } from 'utils/userRoles'
import { SelectInput } from 'components/Select'
import { Button } from 'components/Button'

const defaultValues = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    adminRole: {},
}

export default function AddNewManager({
    open,
    onClose,
    handleOnSubmit,
    ...props
}) {
    const { loading } = useSelector(state => ({
        loading: state.manager.loading,
    }))
    const schema = yup
        .object({
            username: yup.string().required(),
            email: yup.string().email().required(),
            firstname: yup.string().required(),
            lastname: yup.string().required(),
            password: yup.string().required(),
            adminRole: yup.object({
                label: yup.string().required(),
                value: yup.string().required(),
            }),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { isValid },
        watch,
        trigger,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    })

    const onSubmit = data => handleOnSubmit(data)

    useEffect(() => {
        register('adminRole')
    })

    const adminRole = watch('adminRole')

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
            title="Add New Manager"
            className="biz-security-question-modal"
            width="100%"
            maxWidth="800px"
            {...props}
        >
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-6 mb-4">
                    <Input
                        placeholder="Username"
                        {...register('username')}
                        name="username"
                        label="Username"
                        type="text"
                    />
                </div>
                <div className="col-lg-6 mb-4">
                    <Input
                        placeholder="Email Address"
                        {...register('email')}
                        name="email"
                        label="Email Address"
                        type="email"
                    />
                </div>
                <div className="col-lg-6 mb-4">
                    <Input
                        placeholder="First name"
                        {...register('firstname')}
                        name="firstname"
                        label="First name"
                        type="text"
                    />
                </div>
                <div className="col-lg-6 mb-4">
                    <Input
                        placeholder="Last name"
                        name="lastname"
                        {...register('lastname')}
                        label="Last name"
                        type="text"
                    />
                </div>

                <div className="col-lg-6 mb-4">
                    <Input
                        placeholder="Password"
                        {...register('password')}
                        name="password"
                        label="Password"
                        type="password"
                    />
                </div>

                <div className="col-lg-6 mb-4">
                    <SelectInput
                        placeholder="Select Admin Role"
                        value={adminRolesOptions.find(
                            item => item.value === adminRole.value,
                        )}
                        label="Admin Role"
                        name="adminRole"
                        onChange={value => {
                            setValue('adminRole', value)
                            trigger('adminRole')
                        }}
                        options={adminRolesOptions}
                    />
                </div>
                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                        disabled={!isValid}
                        isLoading={loading}
                    >
                        Add Manager
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

AddNewManager.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    customerId: PropTypes.number,
    handleOnSubmit: PropTypes.func,
}
