import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import Modal from 'components/Modals'
import Input from 'components/Input'
import { SelectInput } from 'components/Select'
import { Button } from 'components/Button'
import productSlice from '../../slice'

const defaultValues = {
    product_name: '',
    type: {},
    wallet_address: '',
}

export default function AddProduct({
    open,
    onClose,
    handleOnSubmit,
    ...props
}) {
    const { edit } = open
    const { actions } = productSlice
    const dispatch = useDispatch()
    const { addingProduct } = useSelector(state => ({
        addingProduct: state.product.addingProduct,
    }))
    const schema = yup
        .object({
            product_name: yup.string().required(),
            type: yup.object({
                label: yup.string().required(),
                value: yup.string().required(),
            }),
            wallet_address: yup.string().when('type', {
                is: type => type.value === 'coin',
                then: yup.string().required(),
            }),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { isValid },
        watch,
        trigger,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    })

    const onSubmit = data =>
        handleOnSubmit({ ...data, ...(edit && { id: edit._id }) })

    const type = watch('type')

    useEffect(() => {
        register('type')
    }, [])

    useEffect(() => {
        trigger('wallet_address')
    }, [type])

    const productTypes = [
        { label: 'Card', value: 'card' },
        { label: 'Coin', value: 'coin' },
    ]

    useEffect(() => {
        return () => {
            dispatch(actions.resetUpdate())
        }
    }, [])

    useEffect(() => {
        if (edit) {
            setValue('product_name', edit.name)
            setValue(
                'type',
                productTypes.find(item => item.value === edit.type),
            )
        }
    }, [edit])

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
            title={edit ? 'Edit Product' : 'Add Product'}
            className="biz-security-question-modal"
            width="100%"
            maxWidth="800px"
            {...props}
        >
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-6 mb-4">
                    <Input
                        placeholder="Product Name"
                        {...register('product_name')}
                        name="product_name"
                        label="Product Name"
                        type="text"
                    />
                </div>
                <div className="col-lg-6 mb-4">
                    <SelectInput
                        placeholder="Select product type"
                        value={productTypes.find(
                            item => item.value === type.value,
                        )}
                        label="Product Type"
                        name="type"
                        onChange={value => {
                            setValue('type', value)
                            trigger('type')
                        }}
                        options={productTypes}
                    />
                </div>

                {type.value === 'coin' && (
                    <div className="col-lg-6 mb-4">
                        <Input
                            placeholder="Wallet Address"
                            {...register('wallet_address')}
                            name="wallet_address"
                            label="Wallet Address"
                            type="text"
                        />
                    </div>
                )}

                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                        disabled={!isValid}
                        isLoading={addingProduct}
                    >
                        Add Product
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

AddProduct.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    getProducts: PropTypes.func,
    handleOnSubmit: PropTypes.func,
}
