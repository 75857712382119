import { string, bool, func, node, objectOf, object } from 'prop-types'
import { Modal as ReactResponsiveModal } from 'react-responsive-modal'
import CloseIcon from 'assets/icons/close-icon.svg'
import 'react-responsive-modal/styles.css'
import { ModalWrapper } from './style'

const Modal = ({
    title,
    titleStyle,
    children,
    open,
    onClose,
    closeOnOverlayClick,
    center,
    noCloseIcon,
    className,
    width,
    maxWidth,
    minHeight,
    modalStyle,
}) => (
    <ReactResponsiveModal
        open={open}
        onClose={() => {
            onClose()
            window.document.body.style.overflow = 'scroll!important'
        }}
        center={center}
        classNames={{
            overlay: 'customOverlay',
            modal: `customModal ${className}`,
        }}
        closeOnOverlayClick={closeOnOverlayClick}
        showCloseIcon={false}
        styles={{
            modal: {
                maxWidth,
                minHeight,
                width,
                ...modalStyle,
            },
        }}
    >
        <ModalWrapper>
            {!noCloseIcon && (
                <div className="modal-close-icon" onClick={onClose}>
                    <img src={CloseIcon} alt="close" />
                </div>
            )}
            {title && (
                <div>
                    <p className="modal-title" style={titleStyle}>
                        {title}
                    </p>
                </div>
            )}
            <div className="modal-children">{children}</div>
        </ModalWrapper>
    </ReactResponsiveModal>
)

Modal.propTypes = {
    title: string,
    titleStyle: objectOf(string).isRequired,
    className: string,
    children: node.isRequired,
    open: bool.isRequired,
    onClose: func.isRequired,
    closeOnOverlayClick: bool,
    center: bool,
    width: string,
    noCloseIcon: bool,
    maxWidth: string,
    minHeight: string,
    // eslint-disable-next-line react/forbid-prop-types
    modalStyle: object,
}

Modal.defaultProps = {
    title: '',
    closeOnOverlayClick: true,
    center: false,
    className: '',
    width: null,
    maxWidth: null,
    minHeight: null,
    noCloseIcon: false,
    modalStyle: {},
}

export default Modal
