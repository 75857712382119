import styled from 'styled-components'

export const SelectInputComponentStyle = styled.div`
    .react-select__value-container {
        width: 100%;
        height: 48px;
    }

    .react-select__control {
        height: 48px;
        width: 100%;
        font-size: 16px;
        color: #dadada;
        border: ${({ theme, error }) =>
            `1px solid ${error ? theme.colors?.redError : '#bdbdbdbd'}`};
    }

    .react-select__placeholder {
        font-weight: 400;
        color: #8fa7bc;
    }

    .react-select__indicator-seperator {
        display: none !important;
    }

    .react-select__single-value {
        font-weight: 400;
        color: ${({ theme }) => theme.colors?.black01};
    }
`
