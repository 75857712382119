import React from 'react'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modals'
import { SelectInput } from 'components/Select'
import { Button } from 'components/Button'
import { Status } from 'styles/utils'
import { confirm } from 'components/ConfirmBox'
import { numberWithCommas } from 'utils/numbers'
import {
    InfoGroup,
    Label,
    OverviewSection,
    SectionTitle,
    Value,
} from 'styles/layout'
import toast from 'react-hot-toast'
import { invalidMessage, validMessage } from './message'
import orderSlice from '../../slice'

export default function OrderDetails({ open, onClose, orderId, getAllOrders }) {
    const [orderStatus, setOrderStatus] = useState(null)
    const [message, setMessage] = useState('')
    const [file, setFile] = useState(null)
    const [orderAttended, setOrderAttended] = useState(false)
    const { actions } = orderSlice
    const dispatch = useDispatch()

    const {
        updatedOrder,
        creditOrder,
        order,
        orderLoading,
        creditLoading,
        updateLoading,
    } = useSelector(state => ({
        updatedOrder: state.order.updatedOrder,
        order: state.order.order,
        orderLoading: state.order.orderLoading,
        creditOrder: state.order.creditOrder,
        creditLoading: state.order.creditLoading,
        updateLoading: state.order.updateLoading,
    }))

    const {
        _id,
        product_name,
        return_in_ngn,
        return_in_usd,
        status,
        type,
        fund_disbursed,
        images,
        user,
        items,
    } = order?.data || {}

    const getOrder = () => {
        dispatch(actions.getSingleOrder(orderId))
    }

    const renderItems = itemList => {
        return itemList?.map(item => {
            const orderItems = JSON.parse(item)

            return orderItems?.map(orderItem => (
                <ul key={orderItem?.denomination}>
                    <li>
                        ${orderItem?.denomination} {type} ({' '}
                        {orderItem?.available}pcs)
                    </li>
                </ul>
            ))
        })
    }

    const getDefaultMessage = () => {
        if (orderStatus.value === 'success') {
            setMessage(validMessage)
        } else if (orderStatus.value === 'declined') {
            setMessage(invalidMessage)
        }
    }

    const changeFile = e => {
        const files = e.target.files[0]
        if (files.type && files.type.indexOf('image') === -1) {
            return toast.error(`File  is not an image, select a valid image`)
        }

        if (files.size > 2000000) {
            return toast.error(`File  is too large! select less than 2MB`)
        }

        const reader = new FileReader()
        reader.addEventListener('load')
        reader.readAsDataURL(files)
        setFile(files)
    }

    const handleSubmit = () => {
        if (orderStatus && message) {
            confirm({
                confirmText: `Are you sure you want to update this order?`,
                isLoading: false,
                onConfirm: async () => {
                    const dataForm = new FormData()
                    if (file) {
                        dataForm.append(`images`, file)
                    } else {
                        dataForm.append(`images`, '')
                    }
                    dataForm.append('status', orderStatus.value)
                    dataForm.append('description', message)
                    dispatch(actions.updateOrder({ id: _id, dataForm }))
                },
            })
        }
    }

    const disbuseFund = () => {
        confirm({
            confirmText: `Are you sure you want to credit the user for this offer?`,
            isLoading: false,
            onConfirm: async () => {
                dispatch(
                    actions.creditOrder({ id: _id, amount: return_in_ngn }),
                )
            },
        })
    }

    useEffect(() => {
        if (orderStatus) {
            getDefaultMessage()
        }
    }, [orderStatus])

    useEffect(() => {
        if (updatedOrder || creditOrder) {
            toast.success(updatedOrder?.message || creditOrder?.message)
            setOrderAttended(false)
            setMessage('')
            setOrderStatus(null)
            getOrder()
            getAllOrders()
        }
    }, [updatedOrder, creditOrder])

    useEffect(() => {
        getOrder()
        return () => {
            dispatch(actions.resetUpdatedOrder())
        }
    }, [])

    const options = [
        { label: 'Valid', value: 'success' },
        { label: 'Invalid', value: 'declined' },
    ]

    const labelStyle = {
        fontSize: '14px',
        marginBottom: '10px',
        display: 'block',
    }

    const textareaStyle = {
        width: '100%',
        height: '200px',
        padding: '15px',
        border: '1px solid #bdbdbdbd',
    }

    const valid = orderStatus && message

    return (
        <Modal open={open} onClose={onClose} maxWidth="1000px">
            {orderLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <SectionTitle>Order Information</SectionTitle>
                    <OverviewSection className="pb-3">
                        <InfoGroup>
                            <Label>Product Name</Label>
                            <Value>{product_name}</Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Product Type</Label>
                            <Value>{type}</Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Order Status</Label>
                            <Status status={status}>{status}</Status>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Fund Disbursed</Label>
                            <Status status={fund_disbursed}>
                                {fund_disbursed ? 'Yes' : 'No'}
                            </Status>
                            {status === 'success' && !fund_disbursed && (
                                <Button
                                    variant="link"
                                    onClick={disbuseFund}
                                    isLoading={creditLoading}
                                    disabled={creditLoading}
                                    style={{
                                        width: '50px',
                                        minHeight: 'fit-content',
                                        fontSize: '12px',
                                        height: 'fit-content',
                                        padding: '5px 10px',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Disburse
                                </Button>
                            )}
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Orders</Label>
                            <Value>{renderItems(items)}</Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Expected Return (NGN)</Label>
                            <Value>₦{numberWithCommas(return_in_ngn)}</Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Expected Return (USD)</Label>
                            <Value>${numberWithCommas(return_in_usd)}</Value>
                        </InfoGroup>
                    </OverviewSection>

                    <div>
                        <Label className="mb-2">Product Images</Label>
                        <div className="row mb-4">
                            {images?.map(item => (
                                <div key={item.url} className="col-md-4">
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={item.url}
                                            alt="myecurrency"
                                            style={{
                                                width: '100%',
                                                height: '180px',
                                                objectFit: 'cover',
                                                borderRadius: '15px',
                                            }}
                                        />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    {!fund_disbursed && (
                        <div>
                            {status === 'pending' || orderAttended ? (
                                <div className="mb-4">
                                    <SectionTitle>Order Actions</SectionTitle>
                                    <div className="row mb-4">
                                        <div className="col-md-6 mb-4">
                                            <SelectInput
                                                label="Status"
                                                name="order_status"
                                                placeholder="Please Select"
                                                value={orderStatus}
                                                onChange={val =>
                                                    setOrderStatus(val)
                                                }
                                                options={options}
                                            />
                                        </div>

                                        <div className="col-md-6 mb-4">
                                            <label style={labelStyle}>
                                                Add Image (Optional)
                                            </label>
                                            <input
                                                type="file"
                                                onChange={e => changeFile(e)}
                                            />
                                        </div>

                                        <div>
                                            <label
                                                style={{
                                                    ...labelStyle,
                                                    marginBottom: '5px',
                                                }}
                                            >
                                                Message
                                            </label>
                                            <textarea
                                                value={message}
                                                onChange={e =>
                                                    setMessage(e.target.value)
                                                }
                                                required
                                                style={textareaStyle}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            disabled={!valid || updateLoading}
                                            onClick={handleSubmit}
                                            isLoading={updateLoading}
                                        >
                                            Update Order
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <Button
                                    onClick={() => setOrderAttended(true)}
                                    className="my-4"
                                >
                                    Attend to order again
                                </Button>
                            )}
                        </div>
                    )}

                    <SectionTitle>User Information</SectionTitle>
                    <OverviewSection>
                        <InfoGroup>
                            <Label>Username</Label>
                            <Value>{user?.username}</Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Email</Label>
                            <Value>{user?.email}</Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Name</Label>
                            <Value>
                                {user?.firstname
                                    ? `${user?.firstname} ${user?.lastname}`
                                    : '-'}
                            </Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Balance</Label>
                            <Value>₦{numberWithCommas(user?.balance)}</Value>
                        </InfoGroup>

                        <InfoGroup>
                            <Label>Phone</Label>
                            <Value>{user?.temp_phone || user?.phone}</Value>
                        </InfoGroup>
                    </OverviewSection>
                </>
            )}
        </Modal>
    )
}

OrderDetails.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    orderId: PropTypes.string,
    getAllOrders: PropTypes.func,
}
