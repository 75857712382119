import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { CSVDownload } from 'react-csv'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import Typography from '@mui/material/Typography'
import Table from 'components/Table'
import { Button as ButtonComponent } from 'components/Button'
import ambassadorSlice from './slice'

export default function StudentAmbassador() {
    const LIMIT = 15
    const { actions } = ambassadorSlice
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [skip, setSkip] = useState(0)
    // const [searchValue, setSearchValue] = useState('')
    // const [searchTerm] = useDebounce(searchValue, 1500)

    const { ambassadors, loading, error, exportedAmbassadors } = useSelector(
        state => ({
            ambassadors: state.ambassador.ambassadors,
            loading: state.ambassador.loading,
            error: state.ambassador.error,
            exportedAmbassadors: state.ambassador.exportedAmbassadors,
        }),
    )

    const getAmbassadors = () => {
        // let payload = {
        //     ...(searchTerm && handleSearchType(searchTerm)),
        // }
        dispatch(actions.getAmbassadors({ limit: LIMIT, skip }))
    }

    useEffect(() => {
        getAmbassadors()
    }, [skip])

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    useEffect(() => {
        if (error) {
            toast.error(error?.message)
        }
    }, [error])

    const limitText = (str, value) => {
        console.log({ str, value })
        if (str) return str.length > value ? `${str?.slice(0, value)}...` : str

        return ''
    }

    const count =
        ambassadors?.count > LIMIT ? Math.ceil(ambassadors?.count / LIMIT) : 1

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            renderCell: item => {
                return (
                    <p>
                        {item.firstname} {item.lastname}
                    </p>
                )
            },
        },
        {
            field: 'email',
            headerName: 'Email Address',
            transform: 'lowercase',
        },
        {
            field: 'institution',
            headerName: 'Institution',
        },
        {
            field: 'institution_state',
            headerName: 'State',
        },
        {
            field: 'level',
            headerName: 'Level',
        },
        {
            field: 'phone',
            headerName: 'Phone',
        },
        {
            field: 'position',
            headerName: 'Position Held',
        },
        {
            field: 'events_managed',
            headerName: 'Events Managed',
        },
        {
            field: 'social_media',
            headerName: 'Social Media',
            renderCell: item => {
                return <p>{item.social_media?.join(', ')}</p>
            },
        },
        {
            field: 'communities',
            headerName: 'communities',
            renderCell: ({ communities }) => {
                return <p>{limitText(communities, 100)}</p>
            },
        },
        {
            field: 'initiative',
            headerName: 'Initiatives',
            renderCell: ({ initiative }) => {
                return <p>{limitText(initiative, 100)}</p>
            },
        },
        {
            field: 'why_ambassador',
            headerName: 'Why?',
            renderCell: ({ why_ambassador }) => {
                return <p>{limitText(why_ambassador, 100)}</p>
            },
        },
        {
            field: 'cover_letter',
            headerName: 'Cover Letter',
            renderCell: ({ cover_letter }) => {
                return cover_letter?.length ? (
                    <a
                        href={cover_letter[0]?.url?.replace('.pdf', '.png')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View
                    </a>
                ) : (
                    '-'
                )
            },
        },

        {
            field: 'createdAt',
            headerName: 'Date Applied',
            renderCell: row => {
                return <p>{dayjs(row.createdAt).format('MMMM D, YYYY')}</p>
            },
        },
    ]

    useEffect(() => {
        if (exportedAmbassadors) {
            setTimeout(() => {
                dispatch(actions.resetExport())
            }, [3000])
        }
    }, [exportedAmbassadors])

    const handleExport = () => {
        dispatch(actions.exportAmbassadors())
    }

    return (
        <div>
            <div className="mb-4 d-flex justify-content-between align-items-cente">
                <div className="d-flex align-items-center">
                    <Typography variant="h3" gutterBottom>
                        Student Ambassadors
                    </Typography>
                    <p className="ms-3">{ambassadors?.count}</p>
                </div>

                <div className="d-flex align-items-center">
                    {/* <div className="me-3" style={{ width: '350px' }}>
                        <Input
                            placeholder="Enter username or email address"
                            value={searchValue}
                            name="search"
                            type="text"
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </div> */}
                    <ButtonComponent onClick={handleExport}>
                        Export Table
                    </ButtonComponent>
                </div>
            </div>

            {exportedAmbassadors && (
                <CSVDownload
                    data={exportedAmbassadors.ambassadors.map(item => ({
                        ...item,
                        ...(item.cover_letter &&
                            item.cover_letter[0] && {
                                cover_letter: item.cover_letter[0].url.replace(
                                    '.pdf',
                                    '.png',
                                ),
                            }),
                    }))}
                    target="_blank"
                />
            )}

            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={ambassadors?.ambassadors}
            />
        </div>
    )
}
