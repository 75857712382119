import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'
import getSymbolFromCurrency from 'currency-symbol-map'
import { NumberInputWrapper } from './NumberInput.style'

export default function NumberInput({
    value,
    placeholder,
    onChange,
    currency,
    label,
    ...props
}) {
    const currencySymbol = getSymbolFromCurrency(currency)
    return (
        <NumberInputWrapper>
            {label && <label>{label}</label>}
            <NumericFormat
                value={value}
                placeholder={placeholder}
                thousandSeparator=","
                onValueChange={onChange}
                {...(currency && { prefix: currencySymbol })}
                {...props}
            />
        </NumberInputWrapper>
    )
}

NumberInput.propTypes = {
    value: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
    placeholder: PropTypes.string,
}
