import { call, put, takeLatest } from 'redux-saga/effects'
import toast from 'react-hot-toast'
import { saveToken, saveUserInfoToStorage } from 'utils/storage'
import apis from 'services/user'

import loginSlice from '.'
import appSlice from 'routes/slice'

const { actions } = loginSlice
const { actions: appActions } = appSlice

export function* loginUser(action) {
    try {
        const response = yield call(apis.login, action.payload)
        const { data } = response
        const { token } = data
        if (data.isAdmin) {
            saveToken(token)
            saveUserInfoToStorage(data)
            yield put(actions.loginUserSuccess(data))
            yield put(appActions.setCurrentUser(data))
        } else {
            toast.error('Unauthorized')
        }
    } catch (response) {
        const { data } = response
        yield put(actions.loginUserError(data))
    }
}

export function* loginSaga() {
    yield takeLatest(actions.loginUser.type, loginUser)
}
