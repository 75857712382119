import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    user: false,
    verificationLink: false,
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginUser: state => {
            state.loading = true
            state.error = false
        },
        loginUserSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.user = action.payload
        },
        loginUserError: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        sendVerificationLinkSuccess: (state, action) => {
            state.verificationLink = action.payload
        },
        reset(state) {
            state.loading = false
            state.error = false
            state.user = false
            state.verificationLink = false
        },
    },
})

export default loginSlice
