import { createGlobalStyle } from 'styled-components'
import { override } from 'styles/override'
import { ModalStylesCSS } from 'components/Modals/style'
import { fontFamily } from './font'
import { normalize } from './normalize'
import { variables } from './variables'

const GlobalStyles = createGlobalStyle`
   ${normalize};
   ${variables};
   ${fontFamily};
   ${ModalStylesCSS}

   /**
   * We find it much easier to reason with border-box as the default box-sizing.
   */
   *,
   *::before,
   *::after {
      box-sizing: border-box;
   }

   html {
      /**
    * The default font size for browsers is 16px.
    * We apply a size of 62.5% so that, by default, all math with rems will use
    * 10px as a base instead of 16px.
    */
   }

   body {
      margin: 0;
      min-height: 100%;
      overflow-x: hidden;
      -webkit-font-smoothing: antialiased;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      letter-spacing: -0.03rem;
   }

   html,
   body {
      height: 100%;
      margin: 0;
      padding: 0;
   }


   body{
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
   }

   u,
   a {
      @supports (text-underline-offset: 0.4rem) {
         text-underline-offset: 0.4rem;
         padding-bottom: 0.1rem;
      }
      @supports not (text-underline-offset: 0.4rem) {
         text-underline-position: under;
      }
   }

   .link {
      color: inherit;
      text-decoration: none;
      outline: none;
   }
   .form-control,
   .form-select {
      font-size:initial;
   }
   input,select{
      outline:none;
   }

   .MuiPaper-root.MuiDrawer-paper, header.MuiPaper-root.MuiPaper-elevation4.MuiAppBar-root{
      z-index:10;
   }

   .form-custom-error-message{
      font-size:13px;
      padding-left:2px;
   }

   .react-select__option, .react-select__value-container{
      text-transform:capitalize;
   }

   .cursor-pointer{
      cursor:pointer;
   }

//    .react-responsive-modal-root {
//       z-index: 900;
//   }

//   #react-confirm-alert{
//    z-index:20000;
//   }

//   .react-select__menu-portal{
//    z-index:20000 !important;
//   }

   ${override}
`

export { GlobalStyles }
