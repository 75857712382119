import { string, object, func, bool } from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { Spinner } from 'components/Spinner'
import { Button } from 'components/Button'
import SuccessIcon from 'assets/icons/success.svg'
import ErrorIcon from 'assets/icons/error.svg'

const LoadingModalWrapper = styled.div`
    width: 350px;
    padding: 35px 30px;
`

export function LoadingModal({
    onClose,
    description,
    loading,
    success,
    error,
    onSuccess,
    onError,
}) {
    return (
        <Modal open={open} onClose={onClose} maxWidth="800px">
            <LoadingModalWrapper>
                {loading && (
                    <div>
                        <Spinner />
                        <p className="text-center mt-3"> {description}</p>
                    </div>
                )}

                {success && (
                    <div className="mt-3">
                        <div className="d-flex justify-content-center">
                            <img
                                src={SuccessIcon}
                                alt="success"
                                className=" mb-2"
                            />
                        </div>

                        <h4 className="text-center mb-4">{success?.message}</h4>
                        <div className="d-flex justify-content-center">
                            <Button onClick={onSuccess}>Done</Button>
                        </div>
                    </div>
                )}

                {error && (
                    <div className="mt-3">
                        <div className="d-flex justify-content-center">
                            <img
                                src={ErrorIcon}
                                alt="error"
                                className=" mb-2"
                            />
                        </div>
                        <h4 className="text-center mb-4">{error?.message}</h4>
                        <div className="d-flex justify-content-center">
                            <Button onClick={onError}>Done</Button>
                        </div>
                    </div>
                )}
            </LoadingModalWrapper>
        </Modal>
    )
}

LoadingModal.propTypes = {
    description: string,
    success: object,
    onClose: func,
    loading: bool,
    error: object,
    onSuccess: func,
}
