import axiosCustom from 'utils/axiosCustom'

const apis = {
    getDashboardStats: () => axiosCustom(`/users/admin/stats/dashboard-stats`),
    getOutstandingBalance: () => axiosCustom(`/finances/wallet/admin/`),
    getWalletBalance: () =>
        axiosCustom(`/backoffice/finances/watu-wallet-balance`),
}

export default apis
