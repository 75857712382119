import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'
import * as yup from 'yup'
import { getAllInfoByISO } from 'iso-country-currency'
import PropTypes from 'prop-types'
import Modal from 'components/Modals'
import { SelectInput } from 'components/Select'
import { Button } from 'components/Button'
import { getCountryList } from 'utils/constant'
import NumberInput from 'components/NumberInput'
import rateSlice from '../../slice'

const defaultValues = {
    product_id: {},
    country_code: {},
    high: 0,
    low: 0,
    value: 0,
    featured: false,
}

export default function AddRate({
    open,
    onClose,
    handleOnSubmit,
    products,
    ...props
}) {
    const { edit } = open
    const { actions } = rateSlice
    const dispatch = useDispatch()
    const [currency, setCurrency] = useState({})

    const { addingRate } = useSelector(state => ({
        addingRate: state.rate.addingRate,
    }))

    const schema = yup
        .object({
            product_id: yup.object({
                label: yup.string().required(),
                value: yup.string().required(),
            }),
            country_code: yup.object({
                label: yup.string().required(),
                value: yup.string().required(),
            }),
            high: yup
                .number()
                .required()
                .min(1)
                .when('low', low => {
                    if (low)
                        return yup
                            .number()
                            .required()
                            .min(
                                parseInt(low, 10) + 1,
                                'High must be greater than low',
                            )
                }),
            low: yup.number().required().min(1),
            value: yup.number().required().min(1),

            featured: yup.object({
                label: yup.string().required(),
                value: yup.string().required(),
            }),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        watch,
        trigger,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    })

    const onSubmit = data =>
        handleOnSubmit({ ...data, ...(edit && { id: edit._id }) })

    useEffect(() => {
        register('product_id')
        register('country_code')
        register('high')
        register('low')
        register('value')
        register('featured')
    }, [])

    const featuredOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ]

    useEffect(() => {
        return () => {
            dispatch(actions.resetUpdate())
        }
    }, [])

    useEffect(() => {
        if (edit) {
            console.log(edit)
            setValue('high', edit.high)
            setValue('low', edit.low)
            setValue('value', edit.value)
            setValue(
                'product_id',
                products?.find(item => item.value === edit.product_id._id),
            )
            setValue(
                'country_code',
                countryList?.find(item => item.value === edit.country_code),
            )
            setValue(
                'featured',
                featuredOptions?.find(item => item.value === edit.featured),
            )
        }
    }, [edit])

    const renderErrorMessage = name => (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
                <p className="text-danger form-custom-error-message">
                    {message}
                </p>
            )}
        />
    )

    const sharedOnChange = (name, value) => {
        setValue(name, value)
        trigger(name)
    }

    const product_id = watch('product_id')
    const country_code = watch('country_code')
    const high = watch('high')
    const low = watch('low')
    const value = watch('value')
    const featured = watch('featured')

    const countryList = getCountryList()

    useEffect(() => {
        if (country_code.value) {
            setCurrency(getAllInfoByISO(country_code.value))
        }
    }, [country_code])

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
            title={edit ? 'Edit Rate' : 'Add Rate'}
            className="biz-security-question-modal"
            width="100%"
            maxWidth="800px"
            {...props}
        >
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-6 mb-4">
                    <SelectInput
                        placeholder="Select product"
                        {...(edit && { isDisabled: true })}
                        value={products.find(
                            item => item.value === product_id.value,
                        )}
                        label="Product"
                        name="product_id"
                        onChange={value => sharedOnChange('product_id', value)}
                        options={products}
                    />
                    {renderErrorMessage('product_id')}
                </div>

                <div className="col-lg-6 mb-4">
                    <SelectInput
                        placeholder="Select country"
                        value={countryList.find(
                            item => item.value === country_code.value,
                        )}
                        label="Country"
                        name="country_code"
                        onChange={value =>
                            sharedOnChange('country_code', value)
                        }
                        options={countryList}
                    />
                    {renderErrorMessage('country_code')}
                </div>

                <div className="col-lg-6 mb-4">
                    <NumberInput
                        placeholder="Enter low value"
                        value={low}
                        label="Low"
                        {...(currency.symbol &
                            { prefix: `${currency.symbol} ` })}
                        onChange={valueObject => {
                            sharedOnChange('low', valueObject.floatValue)
                            trigger('high')
                        }}
                    />
                    {renderErrorMessage('low')}
                </div>

                <div className="col-lg-6 mb-4">
                    <NumberInput
                        placeholder="Enter high value"
                        value={high}
                        {...(currency.symbol &
                            { prefix: `${currency.symbol} ` })}
                        label="High"
                        onChange={valueObject =>
                            sharedOnChange('high', valueObject.floatValue)
                        }
                    />
                    {renderErrorMessage('high')}
                </div>

                <div className="col-lg-6 mb-4">
                    <NumberInput
                        placeholder="Enter maximum value"
                        value={value}
                        label="Value"
                        currency="NGN"
                        onChange={valueObject =>
                            sharedOnChange('value', valueObject.floatValue)
                        }
                    />
                    {renderErrorMessage('value')}
                </div>

                <div className="col-lg-6 mb-4">
                    <SelectInput
                        placeholder="Should product feature"
                        value={featuredOptions.find(
                            item => item.value === featured.value,
                        )}
                        label="Featured"
                        name="featured"
                        onChange={value => sharedOnChange('featured', value)}
                        options={featuredOptions}
                    />
                    {renderErrorMessage('featured')}
                </div>

                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                        disabled={!isValid}
                        isLoading={addingRate}
                    >
                        Add Rate
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

AddRate.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    getProducts: PropTypes.func,
    handleOnSubmit: PropTypes.func,
}
