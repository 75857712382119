import { string, bool, node } from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import DashboardLayout from 'components/DashboardLayout'

const ProtectedRoute = ({ path, notProtected, children, pageTitle }) => {
    const { isAuthenticated } = useAuth()

    if (notProtected && isAuthenticated) {
        return (
            <DashboardLayout>
                <Navigate replace to="/dashboard" />;
            </DashboardLayout>
        )
    }

    if (!isAuthenticated && !notProtected) {
        return (
            <Navigate
                replace
                to={{
                    pathname: '/',
                    state: {
                        prevLocation: path,
                        error: 'You need to login first!',
                    },
                }}
                exact
            />
        )
    }

    return notProtected ? (
        children
    ) : (
        <DashboardLayout pageTitle={pageTitle}>{children}</DashboardLayout>
    )
}

ProtectedRoute.propTypes = {
    notProtected: bool,
    path: string.isRequired,
    children: node,
    pageTitle: string,
}

ProtectedRoute.defaultProps = {
    notProtected: false,
}

export default ProtectedRoute
