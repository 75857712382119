export const transactionStatuses = [
    {
        label: 'All',
        value: null,
    },
    {
        label: 'Success',
        value: 'success',
    },
    {
        label: 'Pending',
        value: 'pending',
    },
    {
        label: 'Declined',
        value: 'declined',
    },
]

export const transactionTypes = [
    {
        label: 'All',
        value: null,
    },
    {
        label: 'Credit',
        value: 'credit',
    },
    {
        label: 'Debit',
        value: 'debit',
    },
    {
        label: 'Order',
        value: 'order',
    },
    {
        label: 'Fund',
        value: 'fund',
    },
]

export const transactionCategories = [
    {
        label: 'All',
        value: null,
    },
    {
        label: 'Referral Bonus',
        value: 'referral_bonus',
    },
    {
        label: 'Signup Bonus',
        value: 'signup_bonus',
    },
    {
        label: 'P2P Credit (Incoming)',
        value: 'transfer_credit',
    },
    {
        label: 'P2P Debit (Outgoing)',
        value: 'transfer_debit',
    },
    {
        label: 'Manual Withdrawal',
        value: 'outbound_withdraw',
    },
    {
        label: 'Flutterwave Withdrawal',
        value: 'outbound_withdraw_auto',
    },
]
