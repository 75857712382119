import styled from 'styled-components'

export const NumberInputWrapper = styled.div`
    width: 100%;
    input {
        height: 48px;
        border: 0.5px solid #bbbbbb;
        border-radius: 5px;
        width: 100%;
        padding: 0 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;

        ::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #808080;
        }
    }

    label {
        display: block;
        margin-bottom: 5px;
    }
`
