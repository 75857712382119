/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    loadingBank: false,
    error: false,
    user: null,
    currentUser: null,
    banks: null,
    referContent: '',
    loadingReferContent: false,
    referContentError: false,
    productImageUrl: 'null',
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        clearCurrentUser: state => {
            state.currentUser = null
        },
        getAuthenticatedUser: state => {
            state.loading = true
            state.error = false
        },
        getAuthenticatedUserSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.user = action.payload.data
            if (action.payload.extra === 'currentUser') {
                state.currentUser = action.payload.data
            }
        },
        getAuthenticatedUserError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.user = false
        },
        reset: state => {
            state.loading = false
            state.error = false
            state.user = false
        },
        getAllBanks: state => {
            state.loadingBank = true
            state.error = false
        },
        getAllBanksSuccess: (state, action) => {
            state.loadingBank = false
            state.error = false
            state.banks = action.payload
        },
        getAllBanksError: (state, action) => {
            state.loadingBank = false
            state.error = action.payload
            state.banks = false
        },
        getReferContent: state => {
            state.loadingReferContent = true
            state.referContentError = false
        },
        getReferContentSuccess: (state, action) => {
            state.loadingReferContent = false
            state.referContentError = false
            state.referContent = action.payload
        },
        getReferContentError: (state, action) => {
            state.loadingReferContent = false
            state.referContentError = action.payload
            state.referContent = false
        },
        getProductImageUrl: state => {
            state.productImageUrl = ''
        },
        getProductImageUrlSuccess: (state, action) => {
            state.productImageUrl = action.payload
        },
        logout: () => {},
        logoutSuccess: () => {},
        logoutError: () => {},
    },
})

export default appSlice
