import axiosCustom from 'utils/axiosCustom'
import { paramsObjectToQueryString } from 'utils/parser'

const apis = {
    getProducts: params =>
        axiosCustom(`/backoffice/products${paramsObjectToQueryString(params)}`),

    getSingleProduct: id => axiosCustom(`/backoffice/products/${id}`),

    addProduct: payload =>
        axiosCustom(`/backoffice/products`, {
            method: 'POST',
            data: payload,
        }),

    updateProduct: ({ id, ...payload }) =>
        axiosCustom(`/backoffice/products/${id}`, {
            method: 'PUT',
            data: payload,
        }),

    deleteProduct: id =>
        axiosCustom(`/backoffice/products/${id}`, {
            method: 'DELETE',
        }),

    getRates: params =>
        axiosCustom(
            `/backoffice/products/rates${paramsObjectToQueryString(params)}`,
        ),

    addRate: payload =>
        axiosCustom(`/backoffice/products/rates`, {
            method: 'POST',
            data: payload,
        }),

    updateRate: ({ id, ...payload }) =>
        axiosCustom(`/backoffice/products/rates/${id}`, {
            method: 'PUT',
            data: payload,
        }),

    deleteRate: id =>
        axiosCustom(`/backoffice/products/rates/${id}`, {
            method: 'DELETE',
        }),
}

export default apis
