import Select from 'react-select'
import { ErrorP, Label } from 'components/Input/input.style'
import { string } from 'prop-types'
import { SelectInputComponentStyle } from './SelectInput.styles'

const SelectInput = ({ label, error, name, ...rest }) => {
    return (
        <SelectInputComponentStyle error={error} className="other-selct">
            {label && <Label className="select-label">{label}</Label>}

            <Select
                classNamePrefix="react-select"
                className="red"
                components={{ IndicatorSeparator: () => null }}
                {...rest}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                name={name}
            />
            {error && (
                <ErrorP
                    initial="pageInitial"
                    animate="pageAnimate"
                    exit="pageExit"
                    variants={{
                        pageInitial: {
                            opacity: 1,
                            x: 0,
                            y: -5,
                        },
                        pageAnimate: {
                            opacity: 1,
                            x: 0,
                            y: 0,
                        },
                        pageExit: {
                            opacity: 0,
                        },
                    }}
                >
                    {error}
                </ErrorP>
            )}
        </SelectInputComponentStyle>
    )
}

SelectInput.propTypes = {
    error: string.isRequired,
    label: string,
    name: string.isRequired,
}

SelectInput.defaultProps = {
    label: '',
}

export { SelectInput }
