import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    payments: null,
    updating: false,
    paymentError: null,
    disbursements: null,
    updatedPayment: null,
    pendingTransfers: null,
    queryTransfer: null,
    refundSuccess: null,
    queryError: null,
    querying: false,
    refundError: null,
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        getPaymentRequests: state => {
            state.loading = true
            state.error = false
        },
        getPaymentRequestsSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.payments = action.payload
        },
        getPaymentRequestsError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.payments = null
        },
        updatePaymentRequests: state => {
            state.updating = true
            state.paymentError = false
        },
        updatePaymentRequestsSuccess: (state, action) => {
            state.updating = false
            state.paymentError = false
            state.updatedPayment = action.payload
        },
        updatePaymentRequestsError: (state, action) => {
            state.updating = false
            state.paymentError = action.payload
            state.updatedPayment = null
        },
        getPendingTransfers: state => {
            state.loading = true
            state.error = false
        },
        getPendingTransfersSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.pendingTransfers = action.payload
        },
        getPendingTransfersError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.pendingTransfers = null
        },
        getDisbursements: state => {
            state.loading = true
            state.error = false
        },
        getDisbursementsSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.disbursements = action.payload
        },
        getDisbursementsError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.disbursements = null
        },
        queryTransfer: state => {
            state.querying = true
            state.queryError = false
        },
        queryTransferSuccess: (state, action) => {
            state.querying = false
            state.queryError = false
            state.queryTransfer = action.payload
        },
        queryTransferError: (state, action) => {
            state.querying = false
            state.queryError = action.payload
            state.queryTransfer = null
        },
        refundFailedTransfer: state => {
            state.updating = true
            state.refundError = false
        },
        refundFailedTransferSuccess: (state, action) => {
            state.updating = false
            state.refundError = false
            state.refundSuccess = action.payload
        },
        refundFailedTransferError: (state, action) => {
            state.updating = false
            state.refundError = action.payload
            state.refundSuccess = null
        },
        reset: state => {
            state.loading = false
            state.error = false
            state.payments = null
            state.updatedPayment = null
            state.queryTransfer = null
            state.refundSuccess = null
        },
        resetSelect: (state, action) => {
            if (action.payload) {
                Object.keys(action.payload).forEach(item => {
                    state[item] = action.payload[item]
                })
            }
        },
    },
})

export default paymentSlice
