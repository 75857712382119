/* eslint-disable no-param-reassign */
const TOKEN = 'myecurrency-token'
const USER_DATA = 'myecurrency-user-data'

export const saveToken = token => {
    try {
        localStorage.setItem(TOKEN, token)
    } catch (e) {
        return e
    }
}

export const getToken = () => {
    try {
        return localStorage.getItem(TOKEN)
    } catch (e) {
        return e
    }
}

export const saveToStorage = (key, value) => {
    try {
        localStorage.setItem(key, value)
    } catch (e) {
        return e
    }
}

export const getFromStorage = key => {
    try {
        localStorage.getItem(key)
    } catch (e) {
        return e
    }
}

export const saveUserInfoToStorage = payload => {
    try {
        if (payload.token) {
            delete payload.token
        }
        localStorage.setItem(USER_DATA, JSON.stringify(payload))
        return payload
    } catch (e) {
        return e
    }
}

export const getUserInfoFromStorage = () => {
    try {
        return JSON.parse(localStorage.getItem(USER_DATA))
    } catch (error) {
        return error
    }
}

export const clearStorage = () => {
    try {
        localStorage.clear()
    } catch (e) {
        return e
    }
}

export const clearUserDetails = () => {
    try {
        localStorage.removeItem(TOKEN)
        localStorage.removeItem(USER_DATA)
    } catch (e) {
        return e
    }
}
