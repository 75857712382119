import { getNameList } from 'country-list'

export const getCountryList = () => {
    const countries = getNameList()
    const priority = ['US', 'GB', 'CA', 'AU', 'DE']
    const countryList = Object.keys(countries).map(item => {
        return {
            label: countries[item] === 'GB' ? 'united kingdom' : item,
            value: countries[item],
        }
    })

    const priorityList = countryList.filter(item =>
        priority.includes(item.value),
    )
    const others = countryList.filter(item => !priority.includes(item.value))

    return [...priorityList, ...others]
}
