import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    ambassadors: null,
    exportedAmbassadors: null,
}

const ambassadorSlice = createSlice({
    name: 'ambassador',
    initialState,
    reducers: {
        getAmbassadors: state => {
            state.loading = true
            state.error = false
        },
        getAmbassadorsSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.ambassadors = action.payload
        },
        getAmbassadorsError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.ambassadors = null
        },
        exportAmbassadors: state => {
            state.loading = true
            state.error = false
        },
        exportAmbassadorsSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.exportedAmbassadors = action.payload
        },
        exportAmbassadorsError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.exportedAmbassadors = null
        },
        resetExport: state => {
            state.loading = false
            state.error = false
            state.exportedAmbassadors = null
        },
    },
})

export default ambassadorSlice
