import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import Table from 'components/Table'
import { Status } from 'styles/utils'
import { numberWithCommas } from 'utils/numbers'
import FilterModal from 'components/FilterModal'
import { filters } from '../../utils'
import paymentSlice from '../../slice'

export default function Credits({
    filterModalVisibility,
    setFilterModalVisibility,
}) {
    const LIMIT = 15
    const { actions } = paymentSlice
    const dispatch = useDispatch()
    const [skip, setSkip] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [filterData, setFilterData] = useState({})

    const { loading, updatedPayment, disbursements } = useSelector(state => ({
        loading: state.payment.loading,
        updatedPayment: state.payment.updatedPayment,
        disbursements: state.payment.disbursements,
    }))

    const getDisbursements = () => {
        const { status, ...rest } = filterData
        let payload = {
            ...rest,
            ...(status && { status: status.value }),
        }
        // setCurrentPage(1)
        dispatch(actions.getDisbursements({ limit: LIMIT, skip, ...payload }))
    }

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    useEffect(() => {
        if (updatedPayment) {
            toast.success(updatedPayment?.message)
            getDisbursements()
        }
    }, [updatedPayment])

    useEffect(() => {
        getDisbursements()
    }, [skip, filterData])

    const columns = [
        {
            field: 'user',
            headerName: 'User Email',
            renderCell: ({ user }) => {
                return (
                    <p
                        className="text-lowercase cursor-pointer"
                        onClick={() =>
                            copy(user?.email, {
                                onCopy: () =>
                                    toast.success(
                                        `Email address ${user?.email} copied to clipboard`,
                                    ),
                            })
                        }
                    >
                        <span>
                            {`${
                                user?.email?.length > 10
                                    ? `${user?.email?.slice(0, 10)}...`
                                    : user?.email
                            }`}
                        </span>
                    </p>
                )
            },
        },
        {
            field: 'balance_before',
            headerName: 'Balance Before',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.balance_before)}</p>
            },
        },
        {
            field: 'amount',
            headerName: 'Amount Credited',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.amount)}</p>
            },
        },
        {
            field: 'balance_after',
            headerName: 'Balance After',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.balance_after)}</p>
            },
        },

        {
            field: 'balance',
            headerName: 'Current Balance',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.user?.balance)}</p>
            },
        },
        {
            field: 'parent_category',
            headerName: 'Credit Type',
            renderCell: row => {
                return <p>{row.parent_category}</p>
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: ({ status }) => {
                return <Status status={status}>{status}</Status>
            },
        },
        {
            field: 'ref',
            headerName: 'Reference',
            renderCell: ({ action_id }) => {
                return (
                    <p
                        className="cursor-pointer"
                        onClick={() =>
                            copy(action_id?._id, {
                                onCopy: () =>
                                    toast.success(
                                        `Reference ${action_id._id} copied to clipboard`,
                                    ),
                            })
                        }
                    >
                        {action_id?._id.slice(0, 6)}...
                    </p>
                )
            },
        },
        {
            field: 'action_id.fund_disbursed_by',
            headerName: 'Disbursed by',
            renderCell: ({ action_id }) => {
                return (
                    <span>
                        {action_id?.fund_disbursed_by?.firstname
                            ? `${action_id?.fund_disbursed_by?.firstname}`
                            : action_id?.fund_disbursed_by?.username}
                        {!action_id?.fund_disbursed_by && ' - '}
                    </span>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return (
                    <p>
                        {dayjs(row.createdAt).format('MMM D, YYYY')} {' | '}
                        {dayjs(row.createdAt).format('hh:mmA')}
                    </p>
                )
            },
        },
    ]

    const handleFilter = data => {
        setFilterData(data)
        setCurrentPage(1)
    }

    const count =
        disbursements?.count > LIMIT
            ? Math.ceil(disbursements?.count / LIMIT)
            : 1

    console.log(disbursements)
    return (
        <div>
            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={disbursements?.data}
            />

            <FilterModal
                open={filterModalVisibility}
                onClose={() => setFilterModalVisibility(false)}
                title="Filter Payments"
                handleSubmit={handleFilter}
                filterData={filterData}
                filters={filters}
            />
        </div>
    )
}

Credits.propTypes = {
    filterModalVisibility: PropTypes.bool,
    setFilterModalVisibility: PropTypes.func,
}
