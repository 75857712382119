import { useSelector } from 'react-redux'
import { number, oneOfType, string } from 'prop-types'
import dayjs from 'dayjs'
import { Status } from 'styles/utils'
import {
    InfoGroup,
    Label,
    OverviewSection,
    SectionTitle,
    Value,
} from './Overview.style'
import { numberWithCommas } from 'utils/numbers'

function UserInfo() {
    const { customer } = useSelector(state => ({
        customer: state.customerDetails.customer,
    }))

    const {
        total_referral_above_1k,
        total_referral_earned_for,
        total_referrals,
        user,
    } = customer

    const {
        balance,
        channel,
        createdAt,
        has_received_signup_bonus,
        isDeleted,
        isEmailVerified,
        isPhoneVerified,
        referrer,
        username,
        _id,
    } = user || {}

    const dateCreated = createdAt && dayjs(createdAt).format('MMM DD, YYYY')
    const timeCreated = createdAt && dayjs(createdAt).format('h:mm a')

    return (
        <>
            <form>
                <SectionTitle>User Information</SectionTitle>
                <OverviewSection>
                    <InfoGroup>
                        <Label>Username</Label>
                        <Value>{username}</Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Account Balance</Label>
                        <Value>₦{numberWithCommas(balance)}</Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Signup Channel</Label>
                        <Value>{channel}</Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>User ID</Label>
                        <Value>{_id}</Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Received Signup Bonus</Label>
                        <Status
                            status={has_received_signup_bonus ? 'on' : 'off'}
                        >
                            {has_received_signup_bonus ? 'Yes' : 'No'}
                        </Status>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Referred By</Label>
                        <Value>{referrer || 'N/A'}</Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Email Verified</Label>
                        <Status status={isEmailVerified ? 'on' : 'off'}>
                            {isEmailVerified ? 'Yes' : 'No'}
                        </Status>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Phone Number Verified</Label>
                        <Status status={isPhoneVerified ? 'on' : 'off'}>
                            {isPhoneVerified ? 'Yes' : 'No'}
                        </Status>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Deleted Account</Label>
                        <Status status={isDeleted ? 'on' : 'off'}>
                            {isDeleted ? 'Yes' : 'No'}
                        </Status>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Total Referrals</Label>
                        <Value>{numberWithCommas(total_referrals)}</Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Total Referrals Above 1k</Label>
                        <Value>
                            {numberWithCommas(total_referral_above_1k)}
                        </Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Total Referrals Earned for</Label>
                        <Value>
                            {numberWithCommas(total_referral_earned_for)}
                        </Value>
                    </InfoGroup>

                    <InfoGroup>
                        <Label>Date Created</Label>
                        <Value style={{ textTransform: 'initial' }}>
                            {dateCreated} | {timeCreated}
                        </Value>
                    </InfoGroup>
                </OverviewSection>
            </form>
        </>
    )
}

UserInfo.propTypes = {
    customerId: oneOfType[(number, string)],
}

export default UserInfo
