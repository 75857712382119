import * as React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
// import ListSubheader from '@mui/material/ListSubheader'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SchoolIcon from '@mui/icons-material/School'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import BalanceIcon from '@mui/icons-material/Balance'
import SellIcon from '@mui/icons-material/Sell'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuth } from 'hooks/auth'
import {
    hasPermission,
    ORDERS_PAGE,
    STUDENT_AMBASSADOR_PAGE,
    PRODUCTS_PAGE,
    RATES_PAGE,
    PAYMENTS_PAGE,
    CUSTOMERS_PAGE,
    DASHBOARD_PAGE,
} from 'utils/permission'

// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
// import PeopleIcon from '@mui/icons-material/People'
// import BarChartIcon from '@mui/icons-material/BarChart'
// import LayersIcon from '@mui/icons-material/Layers'
// import AssignmentIcon from '@mui/icons-material/Assignment'

export const MainListItems = () => {
    const { logout, currentUser } = useAuth()

    const { email } = currentUser || {}
    return (
        <React.Fragment>
            {hasPermission(email, DASHBOARD_PAGE) && (
                <Link href="/dashboard" color="inherit" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        Dashboard
                    </ListItemButton>
                </Link>
            )}

            {hasPermission(email, CUSTOMERS_PAGE) && (
                <Link href="/customers" color="inherit" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <PeopleAltIcon />
                        </ListItemIcon>
                        {/* <ListItemText primary="Customers" /> */}
                        Customers
                    </ListItemButton>
                </Link>
            )}

            {hasPermission(email, ORDERS_PAGE) && (
                <Link href="/orders" color="inherit" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <AddShoppingCartIcon />
                        </ListItemIcon>
                        Orders
                    </ListItemButton>
                </Link>
            )}

            {hasPermission(email, PAYMENTS_PAGE) && (
                <Link href="/payments" color="inherit" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <PriceCheckIcon />
                        </ListItemIcon>
                        Payments
                    </ListItemButton>
                </Link>
            )}

            {hasPermission(email, PRODUCTS_PAGE) && (
                <Link href="/products" color="inherit" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <SellIcon />
                        </ListItemIcon>
                        Products
                    </ListItemButton>
                </Link>
            )}

            {hasPermission(email, RATES_PAGE) && (
                <Link href="/rates" color="inherit" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <BalanceIcon />
                        </ListItemIcon>
                        Rates
                    </ListItemButton>
                </Link>
            )}

            {currentUser?.adminRole === 'superadmin' && (
                <Link href="/managers" color="inherit" underline="none">
                    <ListItemButton>
                        <ListItemIcon>
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        Managers
                    </ListItemButton>
                </Link>
            )}

            {hasPermission(email, STUDENT_AMBASSADOR_PAGE) && (
                <Link
                    href="/student-ambassador"
                    color="inherit"
                    underline="none"
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <SchoolIcon />
                        </ListItemIcon>
                        Student Amb
                    </ListItemButton>
                </Link>
            )}

            <ListItemButton>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" onClick={logout} />
                {/* <Link href="#" color="inherit" underline="none">
                    Logout
                </Link> */}
            </ListItemButton>
            {/* <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton> */}
        </React.Fragment>
    )
}

export const secondaryListItems = (
    <React.Fragment>
        {/* <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current month" />
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Last quarter" />
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Year-end sale" />
        </ListItemButton> */}
    </React.Fragment>
)
