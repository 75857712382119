import styled from 'styled-components'

export const CustomerDetailsWrapper = styled.div``

export const Navigation = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
    position: relative;
    border-bottom: 1px solid #d7dce0;
    width: 100%;
    overflow-x: scroll;

    @media (max-width: 550px) {
        justify-content: space-between;
        font-size: 9px;
    }

    @media (max-width: 360px) {
        font-size: 8px;
    }
`

export const NavigationTab = styled.div`
    cursor: pointer;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights?.semiBold};
    color: ${({ theme }) => theme.colors?.grey03};
    text-align: center;
    position: relative;
    white-space: nowrap;

    &.active {
        border-bottom: 2px solid ${({ theme }) => theme.colors?.blue01};
    }
`

export const UserCoursesFooter = styled.div`
    text-align: center;
    background-color: #faf8f5;
    padding: 50px;
    margin-top: 80px;

    button {
        margin-top: 30px;
    }
`
