import axiosCustom from 'utils/axiosCustom'

const apis = {
    login: params =>
        axiosCustom('/auth', {
            method: 'POST',
            data: params,
        }),
    register: params =>
        axiosCustom('/users', {
            method: 'POST',
            data: params,
        }),

    deleteUser: params =>
        axiosCustom(`/users/revoke`, {
            method: 'POST',
            data: params,
        }),
}

export default apis
