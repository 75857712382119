import { bool, oneOf, elementType, string } from 'prop-types'
import { ButtonStyledComponent } from './button.style'

const Button = ({
    isLoading,
    variant,
    children,
    fullWidth,
    type,
    disabled,
    ...rest
}) => {
    return (
        <ButtonStyledComponent
            fullWidth={fullWidth}
            type={type}
            variant={variant}
            disabled={disabled}
            {...rest}
        >
            <span>
                {isLoading ? (
                    <span
                        className="spinner-border "
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <div>{children}</div>
                )}
            </span>
        </ButtonStyledComponent>
    )
}

Button.propTypes = {
    isLoading: bool,
    variant: oneOf(['secondary', 'primary', 'link', 'danger']),
    children: elementType.isRequired,
    type: string,
    fullWidth: bool,
    disabled: bool,
}

Button.defaultProps = {
    isLoading: false,
    fullWidth: false,
    type: 'button',
    disabled: false,
    variant: 'primary',
}

export { Button }
