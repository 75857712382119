import { useEffect, useState } from 'react'
import { CSVDownload } from 'react-csv'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { numberWithCommas } from 'utils/numbers'
import { Status } from 'styles/utils'
import Table from 'components/Table'
import Modal from 'components/Modal'
import Input from 'components/Input'
import CustomerDetails from 'components/CustomerDetails'
import { SelectInput } from 'components/Select'
import customerSlice from './slice'
import FilterModal from './Feature/FilterModal'

export default function Customer() {
    const LIMIT = 15
    const { actions } = customerSlice
    const dispatch = useDispatch()
    const [modalVisibility, setModalVisibility] = useState(false)
    const [customerId, setCustomerId] = useState(null)
    const [filter, setFilter] = useState({})
    const [filterPayload, setFilterPayload] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [skip, setSkip] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [searchTerm] = useDebounce(searchValue, 1500)
    const [searchType, setSearchType] = useState({
        label: 'Username',
        value: 'username',
    })

    const { customers, loading, exportedCustomers } = useSelector(state => ({
        customers: state.customer.customers,
        loading: state.customer.loading,
        error: state.customer.error,
        exportedCustomers: state.customer.exportedCustomers,
    }))

    const handleSearchType = search => ({
        [searchType.value]: search,
    })

    const getCustomers = () => {
        const { user_status, ...remainingFilter } = filter
        return dispatch(
            actions.getCustomers({
                limit: LIMIT,
                skip,
                ...remainingFilter,
                ...(user_status && { user_status: user_status.value }),
                ...(searchTerm && handleSearchType(searchTerm)),
            }),
        )
    }

    useEffect(() => {
        getCustomers()
    }, [filterPayload, skip, searchTerm, searchType])

    const handleInput = value => {
        setFilter({
            ...filter,
            ...value,
        })
    }

    const onApplyFilter = () => {
        setFilterPayload(filter)
        setCurrentPage(1)
        setModalVisibility(false)
    }

    const onClearFilter = () => {
        setFilter({})
        setFilterPayload({})
        setModalVisibility(false)
    }

    useEffect(() => {
        setSkip(LIMIT * (currentPage - 1))
    }, [currentPage])

    const handleCustomerDetails = id => {
        setCustomerId(id)
    }

    const handleExport = () => {
        dispatch(actions.exportCustomers({ ...filter }))
    }

    const options = [
        {
            label: 'Username',
            value: 'username',
        },
        {
            label: 'Email',
            value: 'email',
        },
        {
            label: 'Verified Phone',
            value: 'phone',
        },
        {
            label: 'Unverified Phone',
            value: 'phone_temp',
        },
    ]

    useEffect(() => {
        if (exportedCustomers) {
            setTimeout(() => {
                dispatch(actions.resetExport())
            }, [3000])
        }
    }, [exportedCustomers])

    const count =
        customers?.count > LIMIT ? Math.ceil(customers?.count / LIMIT) : 1

    const columns = [
        {
            field: 'username',
            headerName: 'Username',
            renderCell: row => {
                return (
                    <span
                        onClick={() => handleCustomerDetails(row._id)}
                        style={{ cursor: 'pointer' }}
                        className="text-primary"
                    >
                        {row.username}
                    </span>
                )
            },
        },
        {
            field: 'name',
            headerName: 'Name',
            renderCell: item => {
                return (
                    <p>
                        {item.firstname
                            ? `${item.firstname} ${item.lastname}`
                            : ' - '}
                    </p>
                )
            },
        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            renderCell: ({ phone, phone_temp }) => {
                return <p>{phone || phone_temp}</p>
            },
        },
        {
            field: 'email',
            headerName: 'Email Address',
            transform: 'lowercase',
        },
        {
            field: 'balance',
            headerName: 'Balance',
            renderCell: row => {
                return <p>₦{numberWithCommas(row.balance)}</p>
            },
        },
        {
            field: 'channel',
            headerName: 'Signup Channel',
        },
        {
            field: 'user_status',
            headerName: 'Account Status',
            renderCell: ({ user_status }) => {
                return <Status status={user_status}>{user_status}</Status>
            },
        },

        {
            field: 'createdAt',
            headerName: 'Date Created',
            renderCell: row => {
                return <p>{dayjs(row.createdAt).format('MMMM D, YYYY')}</p>
            },
        },
    ]

    return (
        <div>
            <div className="mb-4 d-flex justify-content-between align-items-cente">
                <div className="d-flex align-items-center">
                    <Typography variant="h3" gutterBottom>
                        Customers
                    </Typography>
                    <p className="ms-3">{customers?.count}</p>
                </div>

                <div className="d-flex align-items-center">
                    <div className="me-3 d-flex" style={{ width: '350px' }}>
                        <Input
                            placeholder={`Enter ${searchType?.value}`}
                            value={searchValue}
                            name="search"
                            type="text"
                            onChange={e => setSearchValue(e.target.value)}
                        />
                        <div style={{ width: '220px' }} className="ms-2">
                            <SelectInput
                                label=""
                                name="searchType"
                                placeholder="Search by"
                                value={searchType}
                                onChange={val => setSearchType(val)}
                                options={options}
                            />
                        </div>
                    </div>
                    <Button
                        variant="outlined"
                        startIcon={<FilterAltRoundedIcon />}
                        onClick={() => setModalVisibility(true)}
                        style={{ height: '45px', marginRight: '15px' }}
                    >
                        Filter
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<FileDownloadIcon />}
                        onClick={handleExport}
                        style={{ height: '45px' }}
                    >
                        Export Data
                    </Button>
                </div>
            </div>

            {exportedCustomers && (
                <CSVDownload data={exportedCustomers?.users} target="_blank" />
            )}

            <Modal
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
            >
                <FilterModal
                    onApplyFilter={onApplyFilter}
                    filter={filter}
                    onChange={handleInput}
                    onClearFilter={onClearFilter}
                />
            </Modal>

            <Table
                isLoading={loading}
                currentPage={currentPage}
                onPageChange={(e, page) => setCurrentPage(page)}
                count={count}
                columns={columns}
                data={customers?.users}
            />

            {!!customerId && (
                <CustomerDetails
                    open={!!customerId}
                    customerId={customerId}
                    onClose={() => {
                        setCustomerId(null)
                        getCustomers()
                    }}
                />
            )}
        </div>
    )
}
