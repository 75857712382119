import { useDispatch, useSelector } from 'react-redux'
import authSlice from 'routes/slice'
import {
    clearUserDetails,
    getToken,
    getUserInfoFromStorage,
    saveToken,
} from 'utils/storage'

const { actions: authActions } = authSlice

function useAuth() {
    const { currentUser } = useSelector(state => ({
        currentUser: state.auth.currentUser,
    }))
    const dispatch = useDispatch()
    const userData = getUserInfoFromStorage()
    const token = getToken()

    function checkSessionValidity() {
        try {
            return !!token
        } catch (err) {
            return false
        }
    }

    async function logout() {
        try {
            clearUserDetails()
            dispatch(
                authActions.logout({
                    headers: { Authorization: `Token ${token}` },
                }),
            )
        } catch (error) {
            return error
        }
    }

    function setAuthenticatedUser(result) {
        dispatch(authActions.setCurrentUser(result))
    }

    function initUserSession() {
        if (checkSessionValidity()) {
            setAuthenticatedUser({ ...userData, token })
            saveToken(token)
            return
        }
        setAuthenticatedUser({})
    }

    return {
        currentUser,
        checkSessionValidity,
        initUserSession,
        setAuthenticatedUser,
        isAuthenticated: checkSessionValidity(),
        logout,
    }
}

export { useAuth }
