import styled, { css } from 'styled-components'

function variantButtonStyles({ theme, variant }) {
    switch (variant) {
        case 'secondary':
            return css`
                color: ${theme.colors?.blue};
                background-color: ${theme.colors?.white};
                border: 1px solid ${theme.colors?.blue};

                &:hover,
                &:active {
                    color: ${theme.colors?.white};
                    background-color: ${theme.colors?.primary};
                    border: 1px solid transparent;
                }

                &:disabled,
                &:disabled:active,
                &:disabled:hover {
                    color: ${theme.colors?.grey};
                    border-color: ${theme.colors?.grey};
                    background-color: ${theme.colors?.grey};
                    cursor: not-allowed;
                }
            `
        case 'danger':
            return css`
                color: ${theme.colors?.white};
                background-color: ${theme.colors?.redError};
                border: 1px solid ${theme.colors?.redError};

                &:hover,
                &:active {
                    color: ${theme.colors?.white};
                    background-color: ${theme.colors?.redError};
                    border: 1px solid transparent;
                    opacity: 0.8;
                }

                &:disabled,
                &:disabled:active,
                &:disabled:hover {
                    color: ${theme.colors?.grey};
                    border-color: ${theme.colors?.grey};
                    background-color: ${theme.colors?.grey};
                    cursor: not-allowed;
                }
            `
        case 'link':
            return css`
                color: ${theme.colors?.primary};
                background: none;
                font-weight: 500;

                &:hover,
                &:active {
                    opacity: 0.8;
                }

                &:disabled,
                &:disabled:active,
                &:disabled:hover {
                    color: ${theme.colors?.grey};
                    cursor: not-allowed;
                }
            `
        default:
        case 'primary':
            return css`
                color: ${theme.colors?.white};
                background-color: ${theme.colors?.primary};

                &:hover,
                &:active {
                    color: ${theme.colors?.white};
                    background-color: ${theme.colors?.primary};
                    opacity: 0.8;
                }

                &:disabled,
                &:disabled:active,
                &:disabled:hover {
                    color: ${theme.colors?.grey};
                    border-color: ${theme.colors?.grey};
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            `
    }
}

export const ButtonStyledComponent = styled.button`
    border: 0;
    padding: 0;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    max-width: 100%;
    text-align: center;
    padding: 16px 24px;
    min-height: 50px;
    lign-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: ${({ theme }) => theme.colors?.transparent};
    border-radius: 3px;
    text-transform: capitalize;
    transition: transform 300ms ease-in-out;
    background-color: ${({ theme }) => theme.colors?.grey};
    color: ${({ theme }) => theme.colors?.grey};
    font-weight: 500;
    transition: all 300ms ease-out;
    justify-content: center;

    &:focus {
        outline: none;
    }

    &:hover {
        transform: scale(0.99);
    }

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `};

    ${variantButtonStyles}

    .spinner-border {
        width: 15px;
        height: 15px;
        border-width: 2px;
        margin-left: 1px;
        transition: all 300ms ease-out;
        padding: 0;
        margin: 0;
    }

    &.confirm-btn-primary {
        color: #fff;
        background-color: var(--color-blue);
        margin-right: 15px;

        &:focus {
            outline: none;
        }
    }

    &.confirm-btn-secondary {
        color: #000;
        background-color: #fff;
        &:focus {
            outline: none;
        }
    }
`
