import { put, takeLatest } from 'redux-saga/effects'
// import apis from 'services/user'
import authSlice from '.'

const { actions } = authSlice

export function* logout() {
    try {
        // const response = yield call(apis.logoutUser, action.payload)
        window.location = '/'
        yield put(actions.logoutSuccess())
    } catch (e) {
        yield put(actions.logoutSuccess(e?.response?.data))
    }
}

export function* authSaga() {
    yield takeLatest(actions.logout.type, logout)
}
