import { useSelector } from 'react-redux'
import { oneOfType, number, string } from 'prop-types'
import { Spinner } from 'components/Spinner'
// import { AlertMessage } from 'components/message'
import UserProfileComponent from './UserProfile'
import { OverviewWrapper } from './Overview.style'
import UserInfo from './UserInfo'

const Overview = ({ customerId }) => {
    const { loading } = useSelector(state => ({
        customer: state.customerDetails.customer,
        loading: state.customerDetails.loading,
    }))

    return (
        <OverviewWrapper>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div className="pb-5">
                        <UserProfileComponent customerId={customerId} />
                    </div>

                    <UserInfo />
                </>
            )}
        </OverviewWrapper>
    )
}

Overview.propTypes = {
    customerId: oneOfType([number, string]),
}

export default Overview
