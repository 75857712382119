import React from 'react'
import { theme, GlobalStyles } from 'theme'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import configureStore from './store/configureStore'
import Router from './routes'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const store = configureStore()
// const renderApp = () =>
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <GlobalStyles />
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </ThemeProvider>
            <Toaster />
        </React.StrictMode>
    </Provider>,
)

// if (process.env.NODE_ENV !== 'production' && module.hot) {
//      module.hot.accept('./components/App', renderApp)
// }

// renderApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
