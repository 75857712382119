import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/customer'

import customerDetailsSlice from '.'

const { actions } = customerDetailsSlice

export function* getSingleCustomer(action) {
    try {
        const response = yield call(apis.getSingleCustomer, action.payload)
        const { data } = response
        yield put(actions.getSingleCustomerSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getSingleCustomerError(data))
    }
}

export function* getCustomerTransactions(action) {
    const { customerId, ...remainingPayload } = action.payload
    try {
        const response = yield call(
            apis.getCustomerTransactions,
            customerId,
            remainingPayload,
        )
        const { data } = response
        yield put(actions.getCustomerTransactionsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getCustomerTransactionsError(data))
    }
}

export function* penalizeCustomer(action) {
    const { customerId, ...remainingPayload } = action.payload
    try {
        const response = yield call(
            apis.penalizeCustomer,
            customerId,
            remainingPayload,
        )
        const { data } = response
        yield put(actions.penalizeCustomerSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.penalizeCustomerError(data))
    }
}

export function* customerDetailsSaga() {
    yield takeLatest(actions.getSingleCustomer.type, getSingleCustomer)
    yield takeLatest(
        actions.getCustomerTransactions.type,
        getCustomerTransactions,
    )
    yield takeLatest(actions.penalizeCustomer.type, penalizeCustomer)
}
