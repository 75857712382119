export const filters = [
    {
        label: 'Start Date',
        key: 'start_date',
        type: 'date',
    },
    {
        label: 'End Date',
        key: 'end_date',
        type: 'date',
    },
    {
        label: 'Type',
        key: 'type',
        type: 'select',
        options: [
            {
                label: 'All',
                value: 'all',
            },
            {
                label: 'Card',
                value: 'card',
            },
            {
                label: 'Coin',
                value: 'coin',
            },
        ],
    },
]
