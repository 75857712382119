import axiosCustom from 'utils/axiosCustom'
import { paramsObjectToQueryString } from 'utils/parser'

const apis = {
    getManagers: params =>
        axiosCustom(`/backoffice/managers${paramsObjectToQueryString(params)}`),

    addManager: payload =>
        axiosCustom(
            `/backoffice/managers${paramsObjectToQueryString(payload)}`,
            {
                method: 'POST',
                data: payload,
            },
        ),
}

export default apis
