export const filters = [
    {
        label: 'Start Date',
        key: 'start_date',
        type: 'date',
    },
    {
        label: 'End Date',
        key: 'end_date',
        type: 'date',
    },
    {
        label: 'Status',
        key: 'status',
        type: 'select',
        options: [
            {
                label: 'Success',
                value: 'success',
            },
            {
                label: 'Pending',
                value: 'pending',
            },
            {
                label: 'Declined',
                value: 'declined',
            },
        ],
    },

    {
        label: 'Fund Disbursed',
        key: 'fund_disbursed',
        type: 'select',
        options: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
    },
]
