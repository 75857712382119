import * as React from 'react'
import Dialog from '@mui/material/Dialog'

type ModalProps = {
    open: boolean
    onClose: () => null
    children: JSX.Element
}

const Modal = ({ open, onClose, children }: ModalProps): JSX.Element => {
    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                {children}
                {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Subscribe</Button>
                </DialogActions> */}
            </Dialog>
        </div>
    )
}

export default Modal
