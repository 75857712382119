type State = {
    getState: () => object
}
type Action = { type: string; payload: object }

const logger =
    (store: State) =>
    (next: (action: Action) => object) =>
    (action: Action) => {
        console.group(action.type)
        console.info('dispatching', action)
        const result = next(action)
        console.log('next state', store.getState())
        console.groupEnd()
        return result
    }

export default logger
