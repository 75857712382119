import Skeleton from '@mui/material/Skeleton'
import { v4 as uuid } from 'uuid'
import { string, objectOf, bool } from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { numberWithCommas } from 'utils/numbers'
import { CardsContainer, Card } from './StatsCards.style'

const useStyles = makeStyles({
    root: {
        borderRadius: '10px',
        background: '#f8f9fa',
    },
})

export function StatsCards({ data, isLoading }) {
    const classes = useStyles()

    function handleValue(element) {
        let content
        if (element[1] == 0) {
            content = '0'
        } else if (element[1]) {
            content = numberWithCommas(element[1])
        } else {
            content = 'N/A'
        }
        return content
    }

    return (
        <CardsContainer className="d-flex flex-wrap">
            {isLoading
                ? Array(3)
                      .fill({})
                      .map(() => (
                          <Skeleton
                              key={uuid()}
                              classes={classes}
                              variant="rect"
                              width="100%"
                              height={118}
                              animation="wave"
                          />
                      ))
                : Object.entries(data).map(element => (
                      <Card key={uuid()}>
                          <div className="text">
                              {element[0].replaceAll('_', ' ')}
                          </div>
                          <div className="value">{handleValue(element)}</div>
                      </Card>
                  ))}
        </CardsContainer>
    )
}

StatsCards.propTypes = {
    data: objectOf(string).isRequired,
    isLoading: bool.isRequired,
}
