import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    addingProduct: false,
    error: false,
    products: null,
    product: null,
    addProduct: null,
    updatedProduct: null,
}

const productSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        getProducts: state => {
            state.loading = true
            state.error = false
        },
        getProductsSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.products = action.payload
        },
        getProductsError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.products = null
        },
        getSingleProduct: state => {
            state.loading = true
            state.error = false
        },
        getSingleProductSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.product = action.payload
        },
        getSingleProductError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.product = null
        },
        addProduct: state => {
            state.addingProduct = true
            state.error = false
        },
        addProductSuccess: (state, action) => {
            state.addingProduct = false
            state.error = false
            state.updatedProduct = action.payload
        },
        addProductError: (state, action) => {
            state.addingProduct = false
            state.error = action.payload
            state.updatedProduct = null
        },
        updateProduct: state => {
            state.addingProduct = true
            state.error = false
        },
        updateProductSuccess: (state, action) => {
            state.addingProduct = false
            state.error = false
            state.updatedProduct = action.payload
        },
        updateProductError: (state, action) => {
            state.addingProduct = false
            state.error = action.payload
            state.updatedProduct = null
        },
        deleteProduct: state => {
            state.addProduct = null
            state.loading = true
            state.error = false
        },
        deleteProductSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.updatedProduct = action.payload
        },
        deleteProductError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.updatedProduct = null
        },
        reset(state) {
            state.loading = false
            state.error = false
            state.updatedProduct = null
            state.products = null
            state.product = null
        },
        resetUpdate(state) {
            state.updatedProduct = null
        },
    },
})

export default productSlice
