import { call, put, takeLatest } from 'redux-saga/effects'
import apis from 'services/payment'

import paymentSlice from '.'
const { actions } = paymentSlice

export function* getPaymentRequests(action) {
    try {
        const response = yield call(apis.getPaymentRequests, action.payload)
        const { data } = response
        yield put(actions.getPaymentRequestsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getPaymentRequestsError(data))
    }
}

export function* getPendingTransfers(action) {
    try {
        const response = yield call(apis.getPendingTransfers, action.payload)
        const { data } = response
        console.log(data)
        yield put(actions.getPendingTransfersSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getPendingTransfersError(data))
    }
}

export function* updatePaymentRequests(action) {
    try {
        const response = yield call(apis.updatePaymentRequests, action.payload)
        const { data } = response
        yield put(actions.updatePaymentRequestsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.updatePaymentRequestsError(data))
    }
}

export function* getDisbursements(action) {
    try {
        const response = yield call(apis.getDisbursements, action.payload)
        const { data } = response
        yield put(actions.getDisbursementsSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.getDisbursementsError(data))
    }
}

export function* queryTransfer(action) {
    try {
        const response = yield call(apis.queryTransfer, action.payload)
        const { data } = response
        yield put(actions.queryTransferSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.queryTransferError(data))
    }
}

export function* refundFailedTransfer(action) {
    try {
        const response = yield call(apis.refundFailedTransfer, action.payload)
        const { data } = response
        yield put(actions.refundFailedTransferSuccess(data))
    } catch (response) {
        const { data } = response
        yield put(actions.refundFailedTransferError(data))
    }
}

export function* paymentSaga() {
    yield takeLatest(actions.getPaymentRequests.type, getPaymentRequests)
    yield takeLatest(actions.getPendingTransfers.type, getPendingTransfers)
    yield takeLatest(actions.updatePaymentRequests.type, updatePaymentRequests)
    yield takeLatest(actions.getDisbursements.type, getDisbursements)
    yield takeLatest(actions.queryTransfer.type, queryTransfer)
    yield takeLatest(actions.refundFailedTransfer.type, refundFailedTransfer)
}
