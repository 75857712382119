import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    stats: {},
    balance: {},
    walletBalance: {},
    walletBalanceLoading: false,
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDashboardStats: state => {
            state.loading = true
            state.error = false
        },
        getDashboardStatsSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.stats = action.payload
        },
        getDashboardStatsError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.stats = null
        },
        getOutstandingBalance: state => {
            state.loading = true
            state.error = false
        },
        getOutstandingBalanceSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.balance = action.payload
        },
        getOutstandingBalanceError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.balance = null
        },
        getWalletBalance: state => {
            state.walletBalanceLoading = true
            state.error = false
        },
        getWalletBalanceSuccess: (state, action) => {
            state.walletBalanceLoading = false
            state.error = false
            state.walletBalance = action.payload
        },
        getWalletBalanceError: (state, action) => {
            state.walletBalanceLoading = false
            state.error = action.payload
            state.walletBalance = null
        },
    },
})

export default dashboardSlice
