import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'components/Modals'
import Overview from './Overview'
import Transactions from './Transactions'
import customerDetailsSlice from './slice'
import {
    CustomerDetailsWrapper,
    Navigation,
    NavigationTab,
} from './CustomerDetails.style'

export default function CustomerDetails({
    open,
    onClose,
    customerId,
    ...props
}) {
    const tabs = ['Overview', 'Transactions']
    const [activeTab, setActiveTab] = useState(tabs[0])
    const dispatch = useDispatch()
    const { actions } = customerDetailsSlice

    useEffect(() => {
        if (customerId) {
            dispatch(actions.getSingleCustomer(customerId))
        }
    }, [customerId])

    const renderActiveSection = () => {
        switch (activeTab) {
            case tabs[1]:
                return <Transactions customerId={customerId} />

            default:
            case '':
            case tabs[0]:
                return <Overview customerId={customerId} />
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
            title="Customer Details"
            className="biz-security-question-modal"
            width="100%"
            maxWidth="1200px"
            {...props}
        >
            <CustomerDetailsWrapper>
                <div className="d-flex justify-content-between">
                    <Navigation>
                        {tabs.map(item => (
                            <NavigationTab
                                className={activeTab === item ? 'active' : ''}
                                onClick={() => setActiveTab(item)}
                                key={item}
                            >
                                {item}
                            </NavigationTab>
                        ))}
                    </Navigation>

                    {/* {noCloseIcon && (
                        <div className="d-flex align-items-end">
                            <Button
                                variant="primary"
                                className="me-4"
                                onClick={handleNextBusiness}
                            >
                                Next Business
                            </Button>
                            <Button variant="danger" onClick={handleEndWork}>
                                End Work
                            </Button>
                        </div>
                    )} */}
                </div>
                <div className="pt-4">{renderActiveSection()}</div>
            </CustomerDetailsWrapper>
        </Modal>
    )
}

CustomerDetails.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    customerId: PropTypes.number,
}
