import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    loading: false,
    error: false,
    customers: null,
    exportedCustomers: null,
}

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        getCustomers: state => {
            state.loading = true
            state.error = false
        },
        getCustomersSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.customers = action.payload
        },
        getCustomersError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.customers = null
        },
        exportCustomers: state => {
            state.loading = true
            state.error = false
        },
        exportCustomersSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.exportedCustomers = action.payload
        },
        exportCustomersError: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.exportedCustomers = null
        },
        resetExport: state => {
            state.loading = false
            state.error = false
            state.exportedCustomers = null
        },
    },
})

export default customerSlice
